import React from "react";
import PublicIcon from '@mui/icons-material/Public';
import { useUser } from "../../../hooks/UserContext";

import {
    Container,
    ContainerItens

} from './styles'

export function AgendaOnline(){

    const {userData}=useUser({})

    const input = document.querySelector('input')

    const copyToClip = () => {
        navigator.clipboard.writeText(input.value).then(()=>{
            alert('Copiado!')
        })
    }


    return(
        <Container>
            <h1 style={{color: 'white'}}>Agenda Online</h1>

            <ContainerItens>

                <div id="divLinkAgendaOnline">
                    <p>Link para Agendamento </p><PublicIcon  style={{marginRight: '3px', color: 'white'}}/>
                </div>

                <div id="divLinkPrincipal">
                    <div id="divLink">
                        <input disabled value={`https://agendanamao.com/agenda/${ userData.collaborator ? userData.UserId : userData.id}`} id="linkAgenda" />
                    </div>
                    <button onClick={copyToClip}>Copiar</button>
                </div>
            </ContainerItens>
        </Container>
    )
}

 const Listdays = [
  
  {
    id: 1,
    label: 'Segunda',
    value: 1
  },
  {
    id: 2,
    label: 'Terça',
    value: 2
  },
  {
    id: 3,
    label: 'Quarta',
    value: 3
  },
  {
    id: 4,
    label: 'Quinta',
    value: 4
  },
  {
    id: 5,
    label: 'Sexta',
    value: 5
  },
  {
    id: 6,
    label: 'Sábado',
    value: 6
  },
  {
    id: 7,
    label: 'Domingo',
    value: 0
  },
]

export default Listdays
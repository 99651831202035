
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import PhonelinkOutlinedIcon from '@mui/icons-material/PhonelinkOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BrowseGalleryOutlinedIcon from '@mui/icons-material/BrowseGalleryOutlined';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PaymentIcon from '@mui/icons-material/Payment';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PercentIcon from '@mui/icons-material/Percent';

import paths from '../../constants/paths'

const listLinks = [
  {
    id: 1,
    label: 'Agenda',
    link: paths.Agenda,
    icon: <DateRangeOutlinedIcon/>
  },
  {
    id: 2,
    label: 'Agendamento Online',
    link: '/AgendaOnline',
    icon: <PhonelinkOutlinedIcon/>
  },
  {
    id: 3,
    label: 'Serviços',
    link: paths.Servicos,
    icon: <SettingsApplicationsOutlinedIcon/>
  },
  {
    id: 4,
    label: 'Colaboradores',
    link: paths.Collaborator,
    icon: <PeopleAltOutlinedIcon/>
  },

  {
    id: 5,
    label: 'Comissão',
    link: paths.Comissoes,
    icon: <PercentIcon/>
  },

  {
    id: 6,
    label: 'Horários',
    link: '/horarios',
    icon: <BrowseGalleryOutlinedIcon/>
  },
  {
    id: 7,
    label: 'Comandas',
    link: '/comandas',
    icon: <FolderOpenIcon/>
  },
  {
    id: 8,
    label: 'Relatório Financeiro',
    link: '/financeiro',
    icon: <AttachMoneyIcon/>
  },
  {
    id: 9,
    label: 'Despesas',
    link: '/despesas',
    icon: <PaymentIcon/>
  },
  {
    id: 10,
    label: 'Dashboard',
    link: '/dashboard',
    icon: <LeaderboardIcon/>
  },
  {
    id: 11,
    label: 'Suporte',
    link: '/suport',
    icon: <SupportAgentIcon/>
  },
  {
    id: 12,
    label: 'Configurações da Conta',
    link: '/config',
    icon: <EditNoteIcon/>
  },

]



export default listLinks
import styled from "styled-components";

export const Container = styled.div`

    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .divPolitica{
        margin-top: 20px;
        height: 100vh;
        width: 70vw;
        display: flex;
        align-items: center;
        justify-content: center;
        
        flex-direction: column;

        .divDescriPolitica{
            height: 70vh;
            width: 50vw;
        }
    }

`


import styled from "styled-components";

export const Container = styled.div`

    width: 100vw;
    height: 50px;
    display: flex;
    background-color: #000000;
    
`







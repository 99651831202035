import React from "react";
import PropTypes from 'prop-types' 
import { UserProvider } from "./UserContext";
import { AgendaProvider } from "./AgendamentoContext";
import { CollProvider } from "./CollaboratorContext";
import {ServiceProvider} from './ServiceContext'

const AppProvider = ({children})=> (

<UserProvider>
    <AgendaProvider>
        <CollProvider>
            <ServiceProvider>
                {children}
            </ServiceProvider>
        </CollProvider>
    </AgendaProvider>
</UserProvider>
) 

AppProvider.propTypes = {
    children: PropTypes.node
}

export default AppProvider
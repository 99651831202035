
const listPlanos = [
    {
        id: 1,
        name: 'Básico',
        price: 'R$14.99',
        functionalities1: 'Apenas 1 Colaborador',
        functionalities2: 'Permitido cadastrar 10 Serviços',
        functionalities3: '40 agendamentos por mês',
        functionalities4: 'Agenda',
        functionalities5: 'Caixa/Comanda',
        functionalities6: 'Gestão Financeira',
        functionalities7: null,
        functionalities8: 'Suporte Grátis',
        functionalities9: null,
        functionalities10: 'Notificações push',
        pagamento: 
        <form target="_blank" action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
            <input type="hidden" name="code" value="11669858D9D9A22224D39F9183976C4F" />
            <input type="hidden" name="iot" value="button" />
            <input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="209" height="48" />
        </form>
        
    }, 
    {
        id: 2,
        name: 'Plano Pro',
        price: 'R$25,00',
        functionalities1: 'Até 3 Colaboradores cadastrados',
        functionalities2: 'Não há limites de Cadasto de Serviços',
        functionalities3: 'Não há limites de Agendamentos',
        functionalities4: 'Agenda',
        functionalities5: 'Caixa/Comanda',
        functionalities6: 'Gestão Financeira',
        functionalities7: 'Dashboard',
        functionalities8: 'Suporte Grátis',
        functionalities9: 'Direito a novas funcionalidades',
        functionalities10: 'Notificações push',
        pagamento: 
        <form target="_blank" action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
            <input type="hidden" name="code" value="8B745A9C2020C45444887FB45CC35873" />
            <input type="hidden" name="iot" value="button" />
            <input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="209" height="48" />
        </form>
    }

]

export default listPlanos
import styled from "styled-components";

export const Container = styled.div`

    width: 65vw;

    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }
`

export const ContainerItens = styled.div`

    #divLinkAgendaOnline{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #000080;
        margin-top: 30px;
    
        p{
            margin-left: 3px;
            color: white;
        }
    }

    #divLinkPrincipal{
        background-color: #A9A9A9; 
        height: 30px;
        display: flex;
        align-items: center;
        
        justify-content: space-between;

        button{
            border: none;
            width: 50px;
            margin-right: 20px;
            height: 20px;
            color: white;
            font-size: 11px;
            background-color: #008000;
            border-radius: 100px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
            &:active{
                opacity: 0.7;
            }
        }

        #divLink{
            background-color: #DCDCDC;
            width: 55vw;
            height: 25px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            padding-left: 7px;

            input{
                width: 50vw;
                border: none;
                background-color: transparent;
            }
        }
    }
 
`
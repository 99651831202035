import React from "react";
import EmDev from '../../../assets/em_desenvolvimento.jpg'

import {Container} from './styles'

export function Dashboard(){

    return(
        <Container>
           <h1 style={{color: 'white'}}>Dashboard</h1>

           <div id="divemDev">
             <img alt="Imagem em Modo em Desenvolvimento" src={EmDev}/>
           </div>
        </Container>
    )
}
import styled from "styled-components";

export const Container = styled.div`

    form{
        display: flex;
        flex-direction: column;
    }

`

export const ContainerItens = styled.div`

    form{
        display: flex;
        flex-direction: column;
        
        p{
            margin-bottom: 1px;
            margin-top: 8px;
            font-size: 13px;
            color: black;
        }

        input{
            background-color: white;
            border: 1px solid black;
            border-radius: 3px;
            height: 25px;
            color: black;
            padding-left: 5px;
        }

        select{
            background-color: white;
            border: 1px solid black;
            border-radius: 3px;
            height: 25px;
            color: black;
        }

        button{
            margin-top: 15px;
            border-radius: 5px;
            border: 1px solid, black;
            background-color: green;
            color: white;
            font-weight: bold;
            height: 22px;
        }
    }

`
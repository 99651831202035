import React, {useState,useEffect} from "react";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Modal from "react-modal";
import {useUser} from '../../hooks/UserContext'
import { useForm } from "react-hook-form"
import apiAgendaNaMao from "../../services/api";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { toast } from 'react-toastify';
import moment from "moment-timezone";

import {Container, 
    ContainerItens,
    ContainerNavModal,
    ListBloqueios
} from './styles'

Modal.setAppElement("#root");

export function BlockAgenda(){

    const {
        register,
        handleSubmit,
    } = useForm()

    const [ModalBlock, setModalBlock] = useState(false)
    const [Colls, setColls] = useState([])
    const [FilterColls, setFilterColls] = useState([])
    const [CollSelect, setCollSelect] = useState(1)
    const [DateSelect, setDateSelect] = useState(moment().format('YYYY-MM-DD'))
    const [Hours, setHours] = useState([])
    const [screm, SetScrem] = useState('addBlock')
    const [Blocks, setBlocks] = useState([])
    const [FilterBlocks, setFilterBlocks] = useState([])
    const [selectedBlock, setSelectedProduct] = useState(null);
    const {userData} = useUser({})

    useEffect(()=>{
       async function loadColl(){
            const {data} = await apiAgendaNaMao.get('collaborator')
            setColls(data)
        }

        async function loadBlocks(){
            const {data} = await apiAgendaNaMao.get('agendaBloqueio')
            setBlocks(data)
        }
        loadColl()
        loadBlocks()
    },[])

    useEffect(()=>{
       
        const filterColl = Colls.filter(coll => coll.UserId === userData.id || coll.UserId === userData.UserId)
        setFilterColls(filterColl)

        const filterBlock = Blocks.filter(blocks => blocks.UserId === userData.id || blocks.UserId === userData.UserId)
        setFilterBlocks(filterBlock)
    },[Colls, userData.id, userData.UserId, Blocks])


    const selectColl = (event) => {
        
        setCollSelect(event.target.value)
    }

    const selectData = (event) => {
        const data = event.target.value
        setDateSelect(data)
    }
   
    
   
        async function LoadsApósSelecColl(event){
            setCollSelect(event.target.value)
            try{

                if(userData.collaborator){

                    let utcAtualUser = moment.tz.guess()
                    const {data} = await apiAgendaNaMao.post('hoursDisponiveis',{
                        date: DateSelect,
                        CollaboratorsId: event.target.value,
                        userId: userData.UserId,
                        utcUser: utcAtualUser
                    })
            
                    setHours(data.agenda)
                    
                }else{

                    let utcAtualUser = moment.tz.guess()
                    const {data} = await apiAgendaNaMao.post('hoursDisponiveis',{
                        date: DateSelect,
                        CollaboratorsId: event.target.value,
                        userId: userData.id,
                        utcUser: utcAtualUser
                    })
            
                    setHours(data.agenda)
                }
                
            }catch{
    
            }
        }

    const formatHours = Hours.map((i)=> Object.values(i)[0]).map((ii)=> Object.values(ii)).map((iii)=> Object.values(iii)[0]).flat()

    const editeHous = formatHours.map(i => moment(i).tz("America/Sao_Paulo").format('HH:mm'))
   
    //função para quando for aciosado vai abrir a modal
    function openModal(){
        setModalBlock(true)
    }

    //função para quando for aciosado vai fechar a modal
    function closeModal(){
        setModalBlock(false)
    }

    const formatDataBlock = FilterBlocks.map(i => ({
        id: i.id,
        title: i.title,
        nameColl: i.Collaborator.name,
        data: moment(i.data).format('DD-MM-YYYY'),
        start: moment(i.start).format('HH:mm'),
        end: moment(i.end).format('HH:mm') 

    }))

    const onSubmit = async (data) => {

        try{

            if(userData.collaborator){

                await apiAgendaNaMao.post('agendaBloqueio',{
                    UserId: userData.UserId,
                    CollaboratorsId: CollSelect,
                    title: data.motivo,
                    days: moment(DateSelect).format('d'),
                    data: new Date(`${DateSelect}T${data.start}`),
                    start: new Date(`2024-02-02T${data.start}`),
                    end: new Date(`2024-02-02T${data.end}`),
                })
            
                closeModal()
                window.location.reload()
            }else{

                await apiAgendaNaMao.post('agendaBloqueio',{
                    UserId: userData.id,
                    CollaboratorsId: CollSelect,
                    title: data.motivo,
                    days: moment(DateSelect).format('d'),
                    data: new Date(`${DateSelect}T${data.start}`),
                    start: new Date(`2024-02-02T${data.start}`),
                    end: new Date(`2024-02-02T${data.end}`),
                })
                closeModal()
                window.location.reload()
            }

        }catch(err){

        }
    }

    async function deleteBlock(selectBlock){

        try{
            if( window.confirm(`Tem certeza que deseja excluir esse Bloqueio?`)){
                await apiAgendaNaMao.delete(`/agendaBloqueio/${selectBlock}`)
                window.location.reload()
            }
        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }


    return(
        <Container>
                <button id="buttonBlockAgenda" onClick={openModal}><EventBusyIcon style={{width: '90px', height: '40px', color: 'black'}}/></button>
            <Modal
                isOpen={ModalBlock}
                onRequestClose={closeModal}
                contentLabel="exemplo modal"
                overlayClassName="modal-overlay"
                className="modal-content"
            >

               <ContainerItens>
                    <ContainerNavModal>
                        <label id="nameHead" >Bloqueios na Agenda</label>
                        <div>
                            <button id="buttonExit" onClick={() => setModalBlock(false)}>X</button>
                        </div>
                        
                    </ContainerNavModal>
                    
                    {screm === 'addBlock' && (
                        <>
                            <form id="formItens" method="post" onSubmit={handleSubmit(onSubmit)}>
                                <button id="ListBloqueio" onClick={() => SetScrem('ListBlock')}>Listar Bloqueios</button>
                                <p>Data</p>
                                <input  onChange={selectData} type="date"/>
                                    
                                <p>Colaborador</p>
                                
                                <select  onChange={LoadsApósSelecColl}  id="selectColl" >
                                    <option selected hidden >Selecione</option>
                                    {FilterColls && FilterColls.map(coll => (
                                        <option key={coll.id} value={coll.id}>{coll.name}</option>
                                    ))}
                                </select>
            
                                <p>Inicio</p> 
                                <select {...register("start")} type="time">
                                    <option selected hidden >Selecione</option>
                                    {formatHours && formatHours.map(i => (
                                        <option key={Object.values(i)}>{i}</option>
                                    ))}
                                </select>
                                    
                                <p>Fim</p>
                                <select  {...register("end")} type="time">
                                    <option selected hidden >Selecione</option>
                                    {formatHours && formatHours.map(i => (
                                        <option key={Object.values(i)}>{i}</option>
                                    ))}
                                </select>
                                    
                                <p>Motivo</p>
                                <textarea {...register("motivo")}/>
                                        
                                <button type="submit">Salvar</button>
                            </form>
                        </>
                    )}

                    {screm === 'ListBlock' && (
                        <>
                            <ListBloqueios>
                                <button id="AddBloqueio" onClick={() => SetScrem('addBlock')}>Adicionar Bloqueios</button>
                            </ListBloqueios>
                            <DataTable  onSelectionChange={(e) => deleteBlock(e.value.id)} selectionMode="single" selection={selectedBlock} dataKey="id" paginator rows={5} stripedRows value={formatDataBlock} tableStyle={{ minWidth: '25rem', fontSize: '12px' , backgroundColor: '#FFF8DC'}}>
                                <Column field="title" header="Motivo" ></Column>
                                <Column field="nameColl" header="Colaborador"></Column>
                                <Column field="data" header="Data"></Column>
                                <Column field="start" header="Inicio"></Column>
                                <Column field="end" header="Fim"></Column>
                                <Column onSelectionChange={(e) => deleteBlock(e.value.id)} body={ <DeleteForeverIcon/>} ></Column>
                            </DataTable>
                        </>
                    )}
                 
                </ContainerItens>
            </Modal>
        </Container>
    )
}
import styled from "styled-components";

export const Container = styled.div`

    width: 100vw;
    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }

`


export const ContainerItens = styled.div`

   
   width: 85vw;
   margin-top: 30px;
   
  
   #divTitleItens{

   }

   #divDate{

        width: 70vw;
        margin-top: 20px;
        

        form{
            
            display: flex;
            flex-direction: column;
            gap: 1px;
            margin: 5px;

            label{
                font-size: 13px;
                color: black;
            }

            select{
                width: 190px;
                margin-bottom: 5px;
                border-radius: 5px;
                border: 1px solid black;
            }

            input{
                width: 100px;
                margin-bottom: 10px;
                border-radius: 5px;
                border: 1px solid black;
            }

            textarea{
                border: 1px solid black;
                border-radius: 5px;
            }

            button{
                margin-top: 5px;
                border-radius: 5px;
                border: 1px solid black;
                background-color: orange;
                font-weight: bold;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
        }
        

   }

   

`

export const ContainerListComissoes = styled.div`

    #divTitleItens{
        margin-top: 20px;
        font-size: 14px;
        margin-bottom: 20px;
    }

`
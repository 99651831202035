import styled from "styled-components";
import Background from '../../assets/background fundo.jpg'
import Name from '../../assets/name.png'
import Estabelecimento from '../../assets/estabelecimento.png'
import Cod from '../../assets/código do país.png'
import Telefone from '../../assets/telefone.png'
import Email from '../../assets/o-email.png'
import Senha from '../../assets/senha1.png'
import ConfirmeSenha from '../../assets/senha.png'

export const Container = styled.div `
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
background: url('${Background}');

`

export const ImgLogo = styled.img `

width: 220px;

`

export const ContainerItens = styled.div `

display: flex;
flex-direction: column;
align-items: center;

h1{
 font-size: 16px;
 text-align: center;
 margin-bottom: 12px;
 
}

form{
    display: flex;
    flex-direction: column;
}

`

export const InputName = styled.input `
background: url('${Name}');
background-repeat: no-repeat;
background-size: 20px;
background-position: 2px;
padding-left: 28px;
width: 250px;
height: 28px;
outline: 1;
border-radius: 5px;
border: 1px solid black;
cursor: pointer;

`



export const InputEstabelecimento = styled.input `
background: url('${Estabelecimento}');
background-repeat: no-repeat;
background-size: 20px;
background-position: 2px;
padding-left: 28px;
width: 250px;
height: 28px;
outline: 1;
border-radius: 5px;
border: 1px solid black;
cursor: pointer;
margin-top: 8px;
`

export const Select = styled.select `
background: url('${Cod}');
background-repeat: no-repeat;
background-size: 20px;
background-position: 2px;
padding-left: 28px;
width: 250px;
height: 28px;
outline: 1;
border-radius: 5px;
border: 1px solid black;
cursor: pointer;
margin-top: 8px;
`

export const InputTel = styled.input `
background: url('${Telefone}');
background-repeat: no-repeat;
background-size: 20px;
background-position: 2px;
padding-left: 28px;
width: 250px;
height: 28px;
outline: 1;
border-radius: 5px;
border: 1px solid black;
cursor: pointer;
margin-top: 8px;
`

export const InputEmail = styled.input `
background: url('${Email}');
background-repeat: no-repeat;
background-size: 20px;
background-position: 2px;
padding-left: 28px;
width: 250px;
height: 28px;
outline: 1;
border-radius: 5px;
border: 1px solid black;
cursor: pointer;
margin-top: 8px;


`

export const InputSenha = styled.input `
background: url('${Senha}');
background-repeat: no-repeat;
background-size: 20px;
background-position: 2px;
padding-left: 28px;
width: 250px;
height: 28px;
outline: 1;
border-radius: 5px;
border: 1px solid black;
cursor: pointer;
margin-top: 8px;
`

export const InputSenhaConfirme = styled.input `
background: url('${ConfirmeSenha}');
background-repeat: no-repeat;
background-size: 20px;
background-position: 2px;
padding-left: 28px;
width: 250px;
height: 28px;
outline: 1;
border-radius: 5px;
border: 1px solid black;
cursor: pointer;
margin-top: 8px;
`

export const ButtonCreate = styled.button `
font-weight: bolder;
width: 250px;
height: 23px;
margin-top: 15px;
cursor: pointer;
background: rgb(17,189,8);
background: linear-gradient(90deg, rgba(17,189,8,1) 0%, rgba(31,111,1,1) 50%, rgba(17,189,8,1) 100%);
color: #fff;
border: none;
border-radius: 5px;
box-shadow: 1px 1px 5px #000;

&:hover{
    opacity: 0.8;

}

&:active{
    opacity: 0.6;

}

`
export const Error = styled.p `

color: red;
font-size: 10px;

`


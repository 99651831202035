import React, { createContext, useContext, useState, useEffect} from "react";
import PropTypes from "prop-types";

const AgendaContext = createContext([])  // Criando o contexto

export const AgendaProvider = ({children}) => {  // Criando provedor, onde vao ficar as informações que quer disponibilizar para toda aplicação.


   const [agendaData, setAgendaData] = useState([])  // Criando stado no react

   const putAgendaData = async agendaInfor =>{  //Criando função para quando for chamada, ela vai armazenar as informações dentro do stado
    setAgendaData(agendaInfor)

    await localStorage.setItem('agendanamao:agendaData', JSON.stringify(agendaInfor)) //Gravando dados no localStorage do navegador e convertendo em string.

   }  

   useEffect(()=> {   // toda vez que aplicação iniciar, vai la no localstorage verifica se tem algo gravado, se tiver ela vai gravar no stado para usar em toda aplicação
    const loadAgendaData = async () => {
        const agendaInfor = await localStorage.getItem('agendanamao:agendaData')  //get recuperando dados do localstorage
        
        if(agendaInfor){
            setAgendaData(JSON.parse(agendaInfor)) // verifica se tem dados no local store, se tiver ele Converte as informações para objeto
        }
         
    }

    loadAgendaData()

},[])

    return(  // Retornar o que eu quero deixar dispoível para a plicação

            <AgendaContext.Provider value={{putAgendaData,agendaData,}}>
                {children}
            </AgendaContext.Provider>
    )
}

export const useAgenda = () =>{          // que sua função é disponibilizar os dados para a nossa aplicação
    const context = useContext(AgendaContext)

    if(!context){   //	Aqui é importante colocar o if para caso a variável context venha errada sabemos onde vai ser o error
        throw new Error('useAgenda must be used with AgendaContext ')
    }

    return context
}


AgendaProvider.propTypes = {
    children: PropTypes.node
}



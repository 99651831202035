import React from "react";
import logo from '../../assets/Agenda na mão.png'
import listPlanos from './listPlan'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import {
    Container,
    ContainerItens
} from './styles'

export function Planos(){


    return(
        <Container>

            <header>
                <img alt="AgendaNaMâo" src={logo}/>
                <button><a target="_blank" rel="noreferrer" href="https://agendanamao.com/">Experimente Grátis</a></button>
            </header>
            <h2>ASSINE AGORA</h2>
            <h1>Planos Disponíveis</h1>
            <ContainerItens>
                
                {listPlanos && listPlanos.map(i => (
                <div id="divCaixaPlanos" key={i.id}>
                    <div id="divName">
                        <h5>{i.name}</h5>
                        <h3 style={{color: '#006400'}}>{i.price}/Mês</h3>
                    </div>

                    <p>{i.functionalities1 === null ? <CloseIcon/> : <CheckIcon />} {i.functionalities1}</p>
                    <p>{i.functionalities2 === null ? <CloseIcon/> : <CheckIcon />} {i.functionalities2}</p>
                    <p>{i.functionalities3 === null ? <CloseIcon/> : <CheckIcon />} {i.functionalities3}</p>
                    <p>{i.functionalities4 === null ? <CloseIcon/> : <CheckIcon />} {i.functionalities4}</p>
                    <p>{i.functionalities5 === null ? <CloseIcon/> : <CheckIcon />} {i.functionalities5}</p>
                    <p>{i.functionalities6 === null ? <CloseIcon/> : <CheckIcon />} {i.functionalities6}</p>
                    <p>{i.functionalities7 === null ? <CloseIcon/> : <CheckIcon />} {i.functionalities7}</p>
                    <p>{i.functionalities8 === null ? <CloseIcon/> : <CheckIcon />} {i.functionalities8}</p>
                    <p>{i.functionalities9 === null ? <CloseIcon/> : <CheckIcon />} {i.functionalities9}</p>
                    <p>{i.functionalities10 === null ? <CloseIcon/> : <CheckIcon />} {i.functionalities10}</p>
                    <a target="_blank">{i.pagamento}</a>
                </div>
                ))}
            </ContainerItens>

        </Container>
    )
}
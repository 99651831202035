import CheckIcon from '@mui/icons-material/Check';
import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';

const listStatus =[
    {
        id: 1,
    },
    {
        id: 2,
        label: "Comandas Concluídas",
        value: "Concluído",
        icon: <CheckIcon style={{width: '19px'}}/>
    },
    {
        id: 3,
        label: "Comandas Pendentes",
        value: "Pendente",
        icon: <SyncIcon style={{width: '19px'}}/>
    },
    {
        id: 4,
        label: "Comandas Canceladas",
        value: "Cancelado",
        icon: <CloseIcon style={{width: '19px'}}/>
    },
    
]

export default listStatus
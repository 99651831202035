import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import * as Yup from "yup"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Listdays from "./days";
import apiAgendaNaMao from "../../services/api";
import {useUser} from '../../hooks/UserContext'
import { Select, Space, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import {
  Container,
  ContainerModal,
  ContainerNavModal,
  ContainerItens,
  InputTime,
  Error,
  SelectDate
} from "./styles"

Modal.setAppElement("#root");



export function ModalAddHours(){

  const schema = Yup.object().shape({

    days: Yup.array().required(),
    collaborator: Yup.string().required('Campo Obrigatório'),
    //services: Yup.array().required(), 
    start: Yup.string().required('Campo Obrigatório'),
    end: Yup.string().required('Campo Obrigatório'), 
  
  })

  const { register, handleSubmit, formState:{ errors } } = useForm({
    resolver: yupResolver(schema)
  });
    
  const [ListColl, setListColl] = useState([])
  const [Services, setService] = useState([])
  const [filterServices, setFilterServices] = useState([])
  const [FilterColl, setFilterColl] = useState([])
  const [open, setopenDiv] = useState(false)
  const {userData} = useUser({})
  const [value, setValue] = useState([]);
  
  const selectProps = {
    value,
    onChange: setValue,
  };
  useEffect(()=>{
      
    async function loadCollaborator(){
      
     const {data} = await apiAgendaNaMao.get('collaborator')
     setListColl(data)
      
    }

    async function loadService(){
        
      const {data} = await apiAgendaNaMao.get('service')
      setService(data)
        
    }

    loadService()
    loadCollaborator()

  },[]) 

  useEffect(()=>{
      
    const filterService = Services.filter(service => service.UserId === userData.id || service.UserId ===  userData.UserId).filter(acc => acc.active === 'Sim')
    setFilterServices(filterService)


    const filterCollaborator = ListColl.filter(coll => coll.UserId === userData.id || coll.UserId === userData.UserId)
    setFilterColl(filterCollaborator)

  },[Services, userData.id, ListColl, userData.UserId ]) 


  const cores = [
    "#6A5ACD", 
    "#836FFF", 
    "#191970", 
    "#000080", 
    "#800000", 
    "#CD5C5C", 
    "#FF7F50",
    "#FF6347",
    "#FF8C00",
    "#DDA0DD",
    "#DA70D6",
    "#9370DB",
    "#8A2BE2",
    "#8B008B",
    "#006400",
    "#008000",
    "#6B8E23",
    "#808000",
    "#556B2F",
    "#20B2AA",
    "#008B8B",
    "#4682B4",
    "#B8860B",
    "#A52A2A",
    "#696969",
    "#778899",
    "#2F4F4F",
    "#A0522D",
]

const colors =  cores[Math.ceil(Math.random() * (cores.length - 1))];


  const onSubmit = async data => {

    try{

      if(userData.collaborator){

        const formatData = {
          collaborator: [
            { id: data.collaborator}
          ],
          services: [
            { id: value}
          ],
          userId:[
            {id: userData.UserId}
          ],
          days: data.days,
          start: new Date(`2024-02-02T${data.start}`),
          end: new Date(`2024-02-02T${data.end}`),
          color: colors
        } 
        if(!value.length){
          alert('falta escolher o serviço')
          return
        }
        await toast.promise(apiAgendaNaMao.post('horarios',formatData),{
          pending: 'Criando Horário',
          success: `Horário Criado com sucesso!`,
        }) 
        window.location.reload()
  
      }else{

        const formatData = {
          collaborator: [
            { id: data.collaborator}
          ],
          services: [
            { id: value}
          ],
          userId:[
            {id: userData.id}
          ],
          days: data.days,
          start: new Date(`2024-02-02T${data.start}`),
          end: new Date(`2024-02-02T${data.end}`),
          color: colors
        } 
        if(!value.length){
          alert('falta escolher o serviço')
          return
        }
        await toast.promise(apiAgendaNaMao.post('horarios',formatData),{
          pending: 'Criando Horário',
          success: `Horário Criado com sucesso!`,
        })
        window.location.reload()
      }
        
    }catch(err){

      const response = (err.response.data)
        toast.error(response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
            theme: "light",
          });
      }

  }

  const [modalCollaborator, setCollaborator] = useState(false)


    //função para quando for aciosado vai abrir a modal
  function openModal(){
   setCollaborator(true)

  }

    //função para quando for aciosado vai fechar a modal
  function closeModal(){
    setCollaborator(false)
  }

  function openDiv(){

    if(!open){

     setopenDiv(true)

    }else{
      setopenDiv(false)
    }
      
  }

const options = filterServices.map(i => ({
  label: i.name,
  value: i.id
}));

const sharedProps = {
  mode: 'multiple',
  style: {
    width: '85%',
    height: '30px',
    
  },
  options,
  placeholder: 'Selecione...',
  maxTagCount: 'responsive',
};

  return(
          
    <Container>

      <button onClick={openModal}> + Horários </button>
  
      <Modal
        isOpen={modalCollaborator}
        onRequestClose={closeModal}
        contentLabel="exemplo modal"
        overlayClassName="modal-overlay"
        className="modal-content"
       >
        <ContainerModal>
          <ContainerNavModal>
            <label id="nameHead" >Adicionar Horários</label>
            <button id="buttonExit" onClick={closeModal}>X</button>
          </ContainerNavModal>
          
          <form noValidate onSubmit={handleSubmit(onSubmit)}  method="post">
            <ContainerItens>
            
              <div>
                {Listdays && Listdays.map(item => (
                <div id="listDays" key={item.id} >
                  <p style={{fontWeight: 'bold'}} >{item.label}</p>
                  <input {...register("days")}  value={item.value} type="checkbox"/>
                </div>
                ))}
                <Error>{errors.days?.message}</Error>
              </div>

              <div  id="divData">

                <label>Colaborador</label>
                <SelectDate {...register("collaborator")} >
                
                  <option value="" selected="selected" hidden disabled>Selecione</option>

                  {FilterColl && FilterColl.map(item => (
                  
                  <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </SelectDate>
                
                <label>Serviços</label>
                <Space direction="vertical"
                    style={{
                      width: '100%',
                      height: '20px'
                    }}
                  >
                  <Select {...sharedProps}  {...selectProps}/>
                </Space>
                
                
                <label>Inicio</label>
                <InputTime  {...register("start", {required: true})} type="time"/>
                <Error>{errors.start?.message}</Error>
                
                <label>Fim</label>
                <InputTime  {...register("end", {required: true})} type="time"/>
                <Error>{errors.end?.message}</Error>

              </div>  

            </ContainerItens>

            <div id="divsubmit">
              <button id="buttonSave" type="submit" >Salvar</button>
            </div>
          
          </form>
      
        </ContainerModal>

      </Modal>

    </Container>
  )

}
import styled from "styled-components";

import Background from '../../assets/background fundo.jpg'
import EmailLogo from '../../assets/o-email.png'
import SenhaLogo from '../../assets/senha1.png'
import OlhoA from '../../assets/olhoAInput.png'
import OlhoF from '../../assets/olhoFInput.png'

export const Container = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url('${Background}');

`


export const ImgLogo = styled.img `
    width: 254px;

`

export const ContainerItens = styled.div `

    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    
    }

    #icon{
        position: relative;
        left: 218px;
        bottom: 29px;
        background: url('${OlhoA}');
        width: 30px;
        height: 30px;
        cursor: pointer;
        background-size: cover;
    }

    #icon.hide{
        position: relative;
        left: 218px;
        bottom: 29px;
        background: url('${OlhoF}');
        width: 30px;
        height: 30px;
        cursor: pointer;
        background-size: cover;

    }

    form{
        display: flex;
        flex-direction: column;
    }

    #link{
        text-decoration: none; 
    }

    #linkImgWhats{
        cursor: pointer;
        position: fixed;
        bottom: 30px;
        right: 30px;
    }

    #imgWhats{
        width: 50px;   
    }

`



export const InputEmail = styled.input `
    background: url('${EmailLogo}');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 2px;
    padding-left: 28px;
    width: 250px;
    height: 28px;
    outline: 1;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;

`


export const InputSenha = styled.input `
    background: url('${SenhaLogo}');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 2px;
    padding-left: 28px;
    outline: 1;
    border-radius: 5px;
    border: 1px solid black;
    width: 250px;
    height: 28px;
    cursor: pointer;
    margin-top: 13px;

`

export const ButtonEntrar = styled.button `
    font-weight: bolder;
    width: 250px;
    height: 23px;
    margin-top: 15px;
    cursor: pointer;
    background: rgb(0,125,255);
    background: linear-gradient(90deg, rgba(0,125,255,1) 0%, rgba(0,1,223,1) 50%, rgba(0,125,255,1) 100%);
    color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #000;
    margin-bottom: 10px;
    &:hover{
        opacity: 0.8;

    }

    &:active{
        opacity: 0.6;

    }

`




export const CreatConta = styled.p `

    margin-right: 5px;

    :hover{
        opacity: 0.8;

    }

    &:active{
        opacity: 0.6;

    }

`

export const ContainerCreat = styled.div `
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

`

export const Error = styled.p `

    color: red;
    font-size: 10px;

`
import styled from "styled-components";

export const Container = styled.div`

    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }

`

export const ContainerFilters = styled.div`
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 150px;
    gap: 10px;
    background-color: #DCDCDC;
    border-radius: 10px;
    margin-top: 30px;

    form{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
  

    button{
        width: 200px;
        background-color: #F4A460;
        border: 1px solid #A9A9A9;
        font-size: 12px;
        font-weight: bold;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        border-radius: 4px;
        margin-top: 10px;
        cursor: pointer;

        &:active{
            opacity: 0.5;
        }
    }

    #divDate{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-left: 20px;

        select{
            margin-top: 1px;
            margin-left: 5px;
            background-color: transparent;
            border: 2px solid #3498db;
            color: black;
            border-radius: 4px;
            width: 113px;
            cursor: pointer;
        }

        p{
            font-size: 14px;
            font-weight: bold;
            margin-top: 7px;
        }

        input{
            background-color: transparent;
            border: 2px solid #3498db;
            color: black;
            border-radius: 4px;
            margin-right: 10px;
            margin-left: 5px;
            cursor: pointer;
        }
    }
`

export const ContainerReceitaBruta = styled.div`
    
    display: grid;
    grid-template-columns: 146px 146px;
    justify-content: space-between;


    @media (min-width: 400px) {
        grid-template-columns: 160px 160px;
    }

    @media (min-width: 560px) {
        grid-template-columns: 190px 190px;
    }

    @media (min-width: 700px) {
        grid-template-columns: 250px 250px;
    }

    @media (min-width: 850px) {
        grid-template-columns: 290px 290px;
    }

    @media (min-width: 1000px) {
        grid-template-columns: 250px 250px 250px;
    }

    @media (min-width: 1200px) {
        grid-template-columns: 350px 350px 350px;
    }

    #divTopoReceitaBruta{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #000080;
        margin-top: 10px;
    
        p{
            margin-left: 3px;
            font-size: 14px;
            color: white;
        }
    }


    #divTopoReceitaDespesas{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #4682B4;
        margin-top: 10px;
    
        p{
            margin-left: 3px;
            color: white;
            font-size: 14px;
        }
    }

    #divTopoReceitaDespesas-despesa{

        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: green;
        margin-top: 10px;

  
        p{
            margin-left: 3px;
            color: white;
            font-size: 14px;
        }
    }

    #divValueReceita{
        background-color: #A9A9A9; 
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #divValueDespesa{
        background-color: #A9A9A9; 
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #divValueReceitaDespesas{
        background-color: #A9A9A9;
        display: flex;
        flex-direction: column;
        
        label{
            margin-bottom: 3px;
            font-size: 14px;
            margin-left: 5px;
        }

        p{
            font-size: 13px;
        }
    }

    #divDentroValor{
        background-color: #DCDCDC;
        width: 37vw;
        height: 25px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding-left: 7px;
    }

`

export const ContainerReceitaService = styled.div`
    width: 80vw;
    
    #divTopReceitaService{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #808080;
        margin-top: 10px;
        width: 297px;
        padding-left: 5px;
        p{
            margin-left: 3px;
            color: white;
        }
    }


    #divExtratoColl{

        display: flex;
        border-bottom: 2px black solid;
        margin-top: 20px;
        width: 100%;
        margin-bottom: 10px;
        h4{
            color: black;
            margin-left: 10px;
        }
    }

    #divItensCollReceita{
        background-color: #808080;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        #divPhotoColl{
            background-color: black;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding: 5px;
            p{
                color: white;
                margin-left: 6px;
                margin-right: 10px;
            }
        }

        #divFaturamentoTotal{
            background-color: green;
            width: auto;
            border-radius: 5px;
            display: flex;
            gap: 10px;
            margin: 6px;

            p{
                margin: 4px;
                font-size: 13px;
                color: black;
                font-weight: bold;
            }
        }

        #divFaturamentoTotalComissao{
            background-color: yellow;
            width: auto;
            border-radius: 5px;
            display: flex;
            gap: 10px;
            margin: 6px;

            p{
                margin: 4px;
                font-size: 13px;
                color: black;
                font-weight: bold;
            }
        }

        #divExtratoServicoPrecoComissao{
            background-color: #4F4F4F;
            width: auto;
            border-radius: 5px;
            display: flex;
            margin: 6px;
            margin-top: 14px;

            p{
                margin: 4px;
                font-size: 13px;
                font-weight: bold;
                color: white;
            }
        }

        #divColumResumo{
            display: grid;
            grid-template-columns: 1fr auto auto ;
            

            #divColum1{
                margin-left: 5px;
                margin-right: 5px;

                #divNameSerico{
                    display: flex;
                    flex-direction: column;
                    background-color: #DCDCDC;
                    width: auto;
                    border-radius: 10px;
                    margin-bottom: 10px;

                    p{
                        margin-bottom: 5px;
                        margin-left: 8px;
                        font-size: 10px;
                        margin-top: 5px;
                    }
                }

                #divValorReal{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: green;
                    width: auto;
                    border-radius: 5px;
                    margin-bottom: 10px;

                    p{
                        margin-bottom: 5px;
                        padding: 0px 5px;
                        font-size: 11px;
                        margin-top: 5px;
                        color: black;
                    }
                }

                #divValorComissao{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: yellow;
                    width: auto;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    
                    p{
                        margin-bottom: 5px;
                        padding: 0px 5px;
                        font-size: 11px;
                        margin-top: 5px;
                        color: black;
                    }
                }
            }
        }
    }


`

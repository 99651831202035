import React from "react";
import {useState} from 'react'
import Modal from "react-modal";
import * as Yup from "yup"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import apiAgendaNaMao from "../../services/api";
import { toast } from 'react-toastify';
import EditNoteIcon from '@mui/icons-material/EditNote';

import {
    Container,
    ContainerItens,
    InputName,
    InputEmail,
    InputTel,
    Div,
    Error,
    P,
    ContainerNavModal
} from "./styles"

Modal.setAppElement("#root");

export function ModalEditeColla({children}){

    
    const schema = Yup.object().shape({
        name: Yup.string(),
        tel: Yup.string(),
        moderator: Yup.boolean(),
        email: Yup.string().email('Digite um email válido'),
    })

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });
  

    const onSubmit = async data => {
        
        //Utilizando o FormData para mandar os dados para api da forma Multipart requerida lá, que não é só dados tem arquivos também
        try{
            const collaboratorDataFormData = new FormData()
            collaboratorDataFormData.append('name', data.name)
            collaboratorDataFormData.append('tel', data.tel)
            collaboratorDataFormData.append('moderator', data.moderator)
            collaboratorDataFormData.append('email', data.email)
            collaboratorDataFormData.append('file', data.file[0])
        
           
            await toast.promise(apiAgendaNaMao.put(`updateCollaborator/${children.id}`, collaboratorDataFormData),{
                pending: 'Editando Colaborador',
                success: `Colaborador Editado com sucesso `,
            })
            
            closeModal()
           window.location.reload()
        
        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                                                                
        }

    }
   
    const [modalEditeColl, setEditeColl] = useState(false)

    //função para quando for aciosado vai abrir a modal
    function openModal(){
    setEditeColl(true)
    }

    //função para quando for aciosado vai fechar a modal
    function closeModal(){
    setEditeColl(false)
    }

    return(
        <Container>
            
            <Div> 
                <button onClick={openModal} > <EditNoteIcon style={{width: '25px',marginTop: "7px"}}/></button>
            </Div>
            
                <Modal
                    isOpen={modalEditeColl}
                    onRequestClose={closeModal}
                    contentLabel="exemplo modal"
                    overlayClassName="modal-overlay"
                    className="modal-content"
                    >
                    
                    <ContainerItens>
                        
                        <ContainerNavModal>
                            <label id="nameHead">Edite Colaborador</label>
                            <button id="buttonExit" onClick={closeModal}>X</button>
                        </ContainerNavModal>
                
                        <form noValidate onSubmit={handleSubmit(onSubmit)} method="put" > 

                            <InputName defaultValue={children.name} type="" placeholder="Nome" {...register("name")} />
                            <Error>{errors.name?.message}</Error>

                            <InputEmail defaultValue={children.email} type="email" placeholder="Email" {...register("email")} />
                            <Error>{errors.email?.message}</Error>
                            
                            <InputTel defaultValue={children.tel} placeholder="Tel" {...register("tel")}/>
                            <Error>{errors.tel?.message}</Error>
                    
                            <P>Selecione uma Foto</P>
                            <input {...register("file")} type="file" accept="image/png, image/jpeg" />
                            <Error>{errors.file?.message}</Error>

                            <P> Administrador?</P>
                            <select defaultValue={children.moderator} noValidate {...register("moderator")}>
                                <option value={true}>Sim</option>
                                <option value={false}>Não</option>
                            </select>
                            <Error>{errors.moderator?.message}</Error>

                            <div id="divsubmit">
                                <button type="submit" id="buttonSave">Atualizar</button>
                            </div>
                        </form>
                    
                    </ContainerItens>
                
                </Modal>
            
        </Container>
    )

}
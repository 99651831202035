import React, {useEffect, useState} from "react";
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import apiAgendaNaMao from "../../../services/api";
import { useUser } from "../../../hooks/UserContext";
import Avatar from '@mui/material/Avatar';
import { toast } from 'react-toastify';
import intervalAgenda from "./intervalAgenda";

import {
    Container,
    ContainerItens
} from './styles'

export function ConfigConta(){

    const schema = Yup.object().shape({
        name: Yup.string(),
        estabelecimento: Yup.string(),
        cod: Yup.string(),
        tel: Yup.string(),
        email:Yup.string(),
        intervalo_agendamento: Yup.number(),
        rua: Yup.string(),
        numero: Yup.string(),
        bairro: Yup.string(),
        estado: Yup.string(),
        cidade: Yup.string(),
        file: Yup.mixed().test( value => {
            return value && value.length >= 0
        }),
        instagram: Yup.string(),
        facebook: Yup.string(),
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const {userData} =useUser({})

    const [Users, setUsers] = useState([])
    const [UsersIdFilter, setUsersIdFilter] = useState([])
    const [PhotoPerfil, setPhotoPerfil] = useState([])

    const onSubmit = async (data) =>  {
        
        try{

            const userFormatData = new FormData()
            
            userFormatData.append('name', data.name)
            userFormatData.append('estabelecimento', data.estabelecimento)
            userFormatData.append('cod', data.cod)
            userFormatData.append('tel', data.tel)
            userFormatData.append('email', data.email)
            userFormatData.append('intervalo_agendamento', data.intervalo_agendamento)
            userFormatData.append('rua', data.rua)
            userFormatData.append('numero', data.numero)
            userFormatData.append('bairro', data.bairro)
            userFormatData.append('estado', data.estado)
            userFormatData.append('cidade', data.cidade)
            userFormatData.append('file', data.file[0])
            userFormatData.append('instagram', data.instagram)
            userFormatData.append('facebook', data.facebook)

             await toast.promise(apiAgendaNaMao.put(`users/${userData.id}`,userFormatData),{
                pending: 'Atualizando dados',
                success: `Dados atualizados com sucesso!`,
             }) 

             
        }catch(err){

            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
   
    useEffect(()=>{
        
        async function loadUsers(){
            const {data} = await apiAgendaNaMao.get('users')
            setUsers(data)
        }
       
        loadUsers()
    },[])

    useEffect(()=>{
        const filterUserId = Users.filter(user => user.id === userData.id)
        setUsersIdFilter(filterUserId)
        setPhotoPerfil(filterUserId.map(i => i.path))

        
    },[Users, userData.id,])
    
    
/*     function photoCarregada(photo){

        const target = photo.target
        const file = target.files[0]

        if(file){
            const reader = new FileReader()

            reader.addEventListener('load', function(e){
                const thisReader = e.target;

                const img = document.createElement('img')
                img.src = thisReader.result
                img.classList.add('picture_img')

                setPhotoPerfil(img.src)
               
            })

            reader.readAsDataURL(file)
        }
    } */

    return(
        <Container>
            <h1 style={{color: 'white'}}>Configurações da Conta</h1>

            <ContainerItens>
                
                {UsersIdFilter &&UsersIdFilter.map(i => (

                <form key={i.id} method="put" onSubmit={handleSubmit(onSubmit)}>
                    <div id="divPhoto">
                        <label>
                            <Avatar style={{width: '100px', height: '100px'}} alt="FOTO" src={`${PhotoPerfil}`} >Sua Foto</Avatar>
                         <span>{errors.file?.message}</span>
                        </label>       
                    </div>
                    <input  {...register("file", {required: false})}  accept="image/png, image/jpeg" type="file" /> 
                    <h4 id="dadosConta">Dados da conta</h4>

                    <div id="list1">
                        <p>Nome
                            <input defaultValue={i.name} {...register("name")}  />
                        </p>

                        <p>Estabelecimento
                        <input {...register("estabelecimento")} defaultValue={i.estabelecimento}/>
                        
                        </p>
                        <p>Código do País
                        <select  {...register("cod")} defaultValue={i.cod}>
                            <option value={55}>Brasil (+55)</option>
                            <option value={56}>Chile (+56)</option>
                            <option value={57}>Colômbia (+57)</option>
                            <option value={34}>Espanha (+34)</option>
                            <option value={1}>Estados Unidos (+1)</option>
                            <option value={52}>México (+52)</option>
                        </select>
                        </p>

                        <p>Tel 
                            <input {...register("tel")} placeholder="(DDD) x-xxxx-xxxx" type="tel" defaultValue={i.tel} />
                        </p>

                        <p>Email
                            <input {...register("email")} type="email" defaultValue={i.email} />
                        </p>

                        <p>Admin
                            <input  disabled defaultValue={i.admin} />
                        </p>

                        <p>Plano
                            <input  disabled defaultValue={i.plano}/>
                        </p>

                        <p>Intervalo entre agendamentos
                            <select  {...register("intervalo_agendamento")} defaultValue={i.intervalo_agendamento}>
                                {intervalAgenda && intervalAgenda.map(iten => (
                                    <option value={iten.value} key={iten.id}>De {iten.label} em {iten.label} minutos</option>
                                ))}
                            </select>
                        </p>
                        </div>

                        <h4 id="dadosConta">Endereço</h4>

                        <div id="listForm"> 
                            <p>Nome da rua
                                <input {...register("rua")} defaultValue={i.rua} />
                            </p>

                            <p>Número do estabelecimento
                                <input {...register("numero")} defaultValue={i.numero} />
                            </p>

                            <p>Bairro
                                <input {...register("bairro")} defaultValue={i.bairro}/>
                            </p>

                            <p>Estado
                                <input {...register("estado")} defaultValue={i.estado}/>
                            </p>

                            <p>Cidade
                                <input {...register("cidade")} defaultValue={i.cidade} />
                            </p>
                        </div>  

                        <h4 id="dadosConta">Rede Sociais</h4>

                        <div id="divRedeSocial">
                            <p>Link instagram
                                <input type="url" {...register("instagram")} defaultValue={i.instagram}/>
                            </p>

                            <p>Link Facebook
                                <input type="url" {...register("facebook")} defaultValue={i.facebook} />
                            </p>
                        </div>
                    <button id="buttonSalve" type="submit">Salvar</button>
                </form>
                ))}
            </ContainerItens>

        </Container>
    )
}
import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  justify-content: end;
  padding-bottom: 1%;
  margin-right: 30px;

  button{
    width: 100px;
    height: 20px;
    font-size: small;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 1px #202020;
    font-weight: bolder;
    cursor: pointer;
    margin-top: 2px;

    &:hover{
      opacity: 0.8;
    }

    &:active{
      opacity: 0.6;
    }

  }

`

export const ContainerModal = styled.div`

  position: absolute;
  top: 30%;
  left: 10%;
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 345px;
  height: 300px;
  box-shadow: 2px 2px 10px #9999;
  border-radius: 1%;

  /* form{
      background: #DCDCDC;
          width: 100%;
  } */



  #buttonCreate{
    font-weight: bolder;
    width: 40%;
    height: 23px;
    cursor: pointer;
    background-color: green;
    color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #000;

    &:hover{
      opacity: 0.8;

    }

    &:active{
      opacity: 0.6;
    }

  }


  #divsubmit{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 15px;
    margin-bottom: 15px;

    #buttonSave{
      width: 60px;
      font-size: 11px;
      height: 20px;
      margin-top: 15px;
      cursor: pointer;
      background-color: green;
      color: #fff;
      border: none;
      border-radius: 5px;
      box-shadow: 1px 1px 5px #000;
      padding-top: 1px;

      &:hover{
        opacity: 0.8;

      }

      &:active{
        opacity: 0.6;

      }
    }   
  }

`


export const ContainerNavModal = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background: #696969;
  display: flex;
  justify-content: space-between;
  align-items: center;

  #nameHead{
    color: #ffffff;
    margin-left: 10px;
    font-size: 12px;
    font-weight: normal;
  }
  
  #buttonExit{
    font-size: 18px;
    margin-top: 2px;
    cursor: pointer;
    color: #ffffff;
    border: none;
    font-weight: bold;
    background: transparent;
    width: 20px;
    border: none;
    margin-right: 5px;


    &:hover{
      opacity: 0.8;
      background: red;
      color: #fff;
    }

    &:active{
      opacity: 0.6;  
    }
  }

`


export const ContainerItens = styled.div `
  display: grid;
  grid-template-columns: 150px 140px;
  border-radius: 10px;
  justify-content: center;

  label{
    font-weight: bold;
    margin-top: 11px;
  }

 .ant-select-selector{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 23px;
    span{
      color: black;
    }
 }
 .ant-select-show-search:where(.css-dev-only-do-not-override-1gwfwyx).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
    background: transparent;
    border: 1px solid #A9A9A9;
    
  }

  
  .ant-select-selection-overflow{
    margin-bottom: 10px;
    
  }
  
  .ant-select-selection-item{
    width: 60px;
    
  }

  .ant-select-selection-item-content{
    width: 80px;

  }


  .ant-select-arrow{
    svg{
      margin-bottom: 5px;
    }
  }

 .ant-select-selection-item{
  font-size: 10px;
  height: 15px;
  display: flex;
  align-items: center;
  
 }

  #listDays{  
    display: grid;
    grid-template-columns: 40% 70%  ;
    font-size: 12px;
    margin-top: 13px;
    margin-left: 10px;
  
    input{
      cursor: pointer;
    }
  }

  #divData{
    margin-left: 22px;
    font-size: 12px;
    display: flex;
    align-items: baseline;
    flex-direction: column;
  }

  #multiselect {
    width: 100px;
    font-size: 12px;
    font-weight: bolder;
  }



`

export const InputTime = styled.input `
  width: 100px;
  background: none;
  border: 1px solid #A9A9A9;
  outline: 1;
  border-radius: 5px;
  cursor: pointer;
`

export const Error = styled.p `

  color: red;
  font-size: 8px;

`

export const SelectDate = styled.select `
  width: 100px;
  height: 23px;
  display: flex;
  background: none;
  outline: 1;
  border-radius: 5px;
  border: 1px solid #A9A9A9;
  cursor: pointer;
  font-size: 12px;
  padding-left: 5px;
`




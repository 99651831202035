import React, {createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const CollContext = createContext([])

export const CollProvider = ({ children }) => {

const [collaborators, setCollaborator] = useState([])

const putCollData = async (collInfor) =>{
setCollaborator(collInfor)

await localStorage.setItem('agendanamao:colaboradorData', JSON.stringify(collInfor))

}

useEffect(()=> { 
    
    // toda vez que aplicação iniciar, vai la no localstorage verifica se tem algo gravado, se tiver ela vai gravar no stado para usar em toda aplicação
    const loadCollData = async () => {

    const collaboratorInfor = await localStorage.getItem('agendanamao:colaboradorData')  //get recuperando dados do localstorage
        
        if(collaboratorInfor){
            setCollaborator(JSON.parse(collaboratorInfor)) // verifica se tem dados no local store, se tiver ele Converte as informações para objeto
        }
         
    }

    loadCollData()

},[])

    return(
        <CollContext.Provider value={{putCollData,collaborators}}>
        {children}
        </CollContext.Provider>
    )
}

export const useContextColl = () => {

const context = useContext(CollContext)

if(!context){
    throw new Error('Deu erro no context de colaborador')
}
return context

}

 CollProvider.propTypes = { 
    children: PropTypes.node
} 
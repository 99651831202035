import React from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DateRangeIcon from '@mui/icons-material/DateRange';

import {Container,ContainerItens} from "./styles"

export function Suporte(){

    return(
        <Container>
            <h1 style={{color: 'white'}}>Suporte</h1>

            <ContainerItens>

                <h2><SupportAgentIcon/> Horários de Atendimento</h2>
                
                <p><DateRangeIcon/>Segunda à Sexta || 08h às 18h</p>
                <p><DateRangeIcon/>Sábado || 08h às 12h </p>
                <div id="divSuportWhats">
                    <a href={`https://wa.me//5581992959030?text=Olá,%20gostaria de mais informações.`} rel="noreferrer" target="_blank"><WhatsAppIcon/>
                        <p>Suporte WhatsApp</p>
                    </a>
                </div>
            </ContainerItens>
        </Container>
    )
}
import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import apiAgendaNaMao from "../../../services/api";
import { useUser } from "../../../hooks/UserContext";
import moment from "moment-timezone";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { toast } from 'react-toastify';

import {
    Container,
    ContainerItens,
    InputCategory,
    ContainerDespesas
} from './styles'


export function Despesas(){

    const [screm, setScrem] = useState('Á vista')
    const [Despesas, setDespesas] = useState([])
    const [DespesasFilter, setDespesasFilter] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null);
    const {userData}=useUser({})

    
    const schema = Yup.object().shape({
        descricao: Yup.string().required('Campo Obrigatório'),
        categoria: Yup.string(),
        data_pagamento: Yup.date(),
        data_vencimento: Yup.date(),
        tipo_pagamento: Yup.string(),
        valor_total: Yup.string().required('Campo Obrigatório'),
        quantidade_parcela: Yup.number()
    })

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    
    function selectedPagament(e){ 
     setScrem(e)
    }

    //formatando o valor para a moeda real
    let formatReal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

    const onSubmit =  async  data => {

        try{

            if(userData.collaborator){

                if(data.data_vencimento === undefined){

                    try{
                        const response =  await apiAgendaNaMao.post('/despesas',{
                            UserId: userData.UserId,
                            descricao: data.descricao,
                            categoria: data.categoria,
                            data_pagamento:  moment(data.data_pagamento).format('YYYY-MM-DDTHH:mm'),
                            data_vencimento: data.data_vencimento,
                            tipo_pagamento: data.tipo_pagamento,
                            valor_total: parseInt(data.valor_total),
                            quantidade_parcela: data.quantidade_parcela
                        }) 

                      
                        window.location.reload()
                        return response
            
                    }catch(err){
                        const response = (err.response.data)
                        toast.error(response, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } 

                }else{
                    try{
                        const response = await apiAgendaNaMao.post('/despesas',{
                            UserId: userData.UserId,
                            descricao: data.descricao,
                            categoria: data.categoria,
                            data_pagamento:  moment(data.data_pagamento).format('YYYY-MM-DDTHH:mm'),
                            data_vencimento: moment(data.data_vencimento).format('YYYY-MM-DDTHH:mm'),
                            tipo_pagamento: data.tipo_pagamento,
                            valor_total: parseInt(data.valor_total),
                            quantidade_parcela: data.quantidade_parcela
                        }) 
                        window.location.reload()
                        return response
            
                    }catch(err){
                        const response = (err.response.data)
                        toast.error(response, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }

            }else{
                if(data.data_vencimento === undefined){

                    try{
                        const response =  await apiAgendaNaMao.post('/despesas',{
                            UserId: userData.id,
                            descricao: data.descricao,
                            categoria: data.categoria,
                            data_pagamento:  moment(data.data_pagamento).format('YYYY-MM-DDTHH:mm'),
                            data_vencimento: data.data_vencimento,
                            tipo_pagamento: data.tipo_pagamento,
                            valor_total: parseInt(data.valor_total),
                            quantidade_parcela: data.quantidade_parcela
                        }) 
                        window.location.reload()
                        return response
            
                    }catch(err){
                        const response = (err.response.data)
                        toast.error(response, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } 

                }else{
                    try{
                        const response = await apiAgendaNaMao.post('/despesas',{
                            UserId: userData.id,
                            descricao: data.descricao,
                            categoria: data.categoria,
                            data_pagamento:  moment(data.data_pagamento).format('YYYY-MM-DDTHH:mm'),
                            data_vencimento: moment(data.data_vencimento).format('YYYY-MM-DDTHH:mm'),
                            tipo_pagamento: data.tipo_pagamento,
                            valor_total: parseInt(data.valor_total),
                            quantidade_parcela: data.quantidade_parcela
                        }) 
                        window.location.reload()
                        return response
            
                    }catch(err){
                        const response = (err.response.data)
                        toast.error(response, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }
            }

        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }


    };

    useEffect(()=>{
        async function loadDespesas(){
            const {data} = await apiAgendaNaMao.get('/despesas')
            setDespesas(data)
        }
        loadDespesas()
    },[])



    useEffect(()=>{

        const filterDespesas = Despesas.filter(i => i.UserId === userData.id || i.UserId === userData.UserId )
        setDespesasFilter(filterDespesas)
        
    },[Despesas, userData.id, userData.UserId])
  

    const formatDate = DespesasFilter.map(i => ({
        id: i.id,
        descricao: i.descricao,
        categoria: i.categoria,
        data_pagamento: moment(i.data_pagamento).tz("America/Sao_Paulo").format('DD-MM-YYYY'),
        data_vencimento: i.data_vencimento ===  null? '' : moment(i.data_vencimento).tz("America/Sao_Paulo").format('DD-MM-YYYY'),
        quantidade_parcela: i.quantidade_parcela,
        tipo_pagamento: i.tipo_pagamento,
        valor_total: formatReal.format(i.valor_total)
    }))

 

    async function deleteDespesa(selectedProducts){

        try{
            if( window.confirm(`Tem certeza que deseja excluir essa despesa`)){
                await apiAgendaNaMao.delete(`/despesas/${selectedProducts}`)
                window.location.reload()
            } 
        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        
    }

    return(
        <Container>
            <h1 style={{color: 'white'}}>Despesas</h1>
            <ContainerItens>
                
                <h3>Adicionar Despesas</h3>   
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <label>Descrição</label>
                    <input {...register("descricao", {required: true})} />
                    <p>{errors.descricao?.message}</p>

                    <label>Categoria</label>
                    <a>
                        <InputCategory  {...register("categoria")}/>
                        Exemplos
                    </a>
                    
                    <label>Forma Pagamento</label>
                    <select {...register("tipo_pagamento")} onChange={e => {selectedPagament(e.target.value)}}> 
                        <option hidden >Selecione uma opção</option>
                        <option value='Á vista'>Á vista</option>
                        <option value='Cartão'>Cartão de Crédito</option>
                    </select>
                    
                    <label>Data Pagamento</label>
                    <input  {...register("data_pagamento")} type="date"/>

                    <label>Valor total</label>
                    <input {...register("valor_total", {required: true})} type="number" />
                    <p>{errors.valor_total?.message}</p>
                    
                    {screm === 'Cartão' && (
                        <>
                            <label>Data Vencimento</label>
                            <input {...register("data_vencimento")} type="date"/>
                            <label>Quantidade de Parcela</label>
                            <input {...register("quantidade_parcela")} type="number"/>
                        </>
                    )}
                    <button type="submit" >Salvar</button>
                </form>
            </ContainerItens>

            <ContainerDespesas>
            <h3>Minhas Despesas</h3> 
            
            <DataTable dataKey="id" onSelectionChange={(e) => deleteDespesa(e.value.id)} selection={selectedProduct} selectionMode="single" paginator rows={10} stripedRows value={formatDate} tableStyle={{ minWidth: '60rem', fontSize: '12px' , backgroundColor: '#FFF8DC'}}>
                <Column field="descricao" header="Descrição" ></Column>
                <Column field="categoria" header="Categoria"></Column>
                <Column field="data_pagamento" header="Data Pagamento"></Column>
                <Column field="tipo_pagamento" header="Forma Pagamento"></Column>
                <Column field="valor_total" header="Valor total"></Column>
                <Column field="data_vencimento" header="Data Vencimento"></Column>
                <Column field="quantidade_parcela" header="Quantidade de Parcela"></Column>
                <Column onSelectionChange={(e) => deleteDespesa(e.value.id)} body={ <DeleteForeverIcon/>}></Column>
            </DataTable>
           
            </ContainerDespesas>
        </Container>
    )

}


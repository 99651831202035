import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    
    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }
`

export const ContainerItens = styled.div`
   
    margin-top: 30px;
    background-color: #DCDCDC;
    height: 110vh;
    width: 90vw;
    border-radius: 10px;

    #divMenu{
        display: flex;
        gap: 15px;
        margin-top: 10px;
        margin-left: 4px;
        margin-bottom: 10px;
    }

    #divFilterComanda{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
        

        p{
            font-size: 15px;
            margin-top: 20px;
            font-family: sans-serif;
            margin-left: 10px;
        }

        input{
            margin-bottom: 20px;
            width: 120px;
            border: 1px solid black;
            border-radius: 5px;
            margin-left: 10px;
            background-color: transparent;
            padding-left: 10px;

        }
    }

    
`

export const ContainerComands = styled.div`
    margin-top: 10px;
    height: 80vh;
    overflow-y: scroll;
    margin-left: 10px;
    
    #listComands{
       display: flex;
       gap: 8px;
       flex-direction: column;
       margin-top: 20px;
       margin-left: 4px;
       width: 85%;
       background-color: #F5DEB3;
       
       border-bottom-right-radius: 10px;
       border-bottom-left-radius: 10px;

       p{
            margin-left: 6px;
            font-size: 13px;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
        }

        textarea{
            width: 95%;
            margin-left: 6px;
            border-radius: 5px;
            font-size: 12px;
        }

       #divStatus{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #F4A460;
            
            height: 30px;
    
            select{
                font-size: 13px;
                border: none;
                margin-right: 6px;
                border-radius: 5px;
                font-weight: bold;
                padding-left: 3px;
                padding-bottom: 1px;
                font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
                &:hover{
                    background-color: #3498db;
                }
            }
        }

        #divDelete{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #A9A9A9;
            margin-top: 10px;
            button{
                border: none;
                cursor: pointer;
                background-color: transparent;
                margin-right: 15px;
            }
        }
    }
`

export const ListLink = styled.a`
    font-size: 13px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: ${props => (props.isactivestatus === 'sim' ? '3px solid #F4A460' : 'transparent')};
    background-color: transparent;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
`
import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useService} from "../../hooks/ServiceContext"
import {useUser} from '../../hooks/UserContext'
import {useContextColl} from "../../hooks/CollaboratorContext"
import apiAgendaNaMao from "../../services/api";
import moment from "moment";
import { toast } from 'react-toastify';

import {
    Container,ContainerItens

} from "./styles"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export function ModalAddComandas(list){

  const [open, setOpen] = React.useState(false);
  const [serviceFilter, setServiceFilter] = useState([])
  const [collFilter, setCollFilter] = useState([])
  const [Hours, setHours] = useState([])
  const [DataSelect, setDataSelect] = useState([])
  const [CollSelect, setCollSelect] = useState([])

  const [ServiceIdSelected, setServiceIdSelected] = useState([])

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {services: services} = useService([])
  const {userData} = useUser({})
  const {collaborators} = useContextColl([])
  const { register, handleSubmit } = useForm()
  
  useEffect(()=>{
    const filterSerive = services.filter(i => i.UserId === userData.id || i.UserId === userData.UserId)
    const filterColl = collaborators.filter(i => i.UserId ===userData.id || i.UserId === userData.UserId)
    setCollFilter(filterColl)
    setServiceFilter(filterSerive)
  },[services, userData.UserId, userData.id, collaborators,CollSelect,DataSelect])

  
  async function loadHous(idService) {
    setServiceIdSelected(idService.target.value)
    let utcAtualUser = moment.tz.guess()
    try{
      if(userData.collaborator){
        const {data} = await apiAgendaNaMao.post('/encaixeAgenda',{
          date: moment().tz("America/Sao_Paulo").format('YYYY-MM-DD'),
          service_id: idService.target.value,
          userId: userData.UserId,
          utcUser: utcAtualUser
        })
       setHours(data.agenda)

       return
      }
      
      const {data} = await apiAgendaNaMao.post('/encaixeAgenda',{
        date: moment().tz("America/Sao_Paulo").format('YYYY-MM-DD'),
        service_id: idService.target.value,
        userId: userData.id,
        utcUser: utcAtualUser
      })
      setHours(data.agenda)
     
    }catch(err){
      alert('Error aos buscar dias (encaixe de clientes)')
    }
  }
  

  const formatData = Hours.map((item, index)=>({
      ano: moment(Object.keys(item)[0]).format('DD-MM-YYYY'),
      data: item,
      posi: index
  }))
  //filtrando data das horas e service selecionado
  


  const filterColl = DataSelect.map(i => Object.values(i).map(i => Object.keys(i))[0]).flat()
    
  const filterCollData = collFilter.filter(coll => filterColl.some(i => i === coll.id))

  const teste = DataSelect.map(i => Object.values(i)[0][CollSelect]).flat()

  function selectData(event){
    
    const DataSelectNumber = parseInt(event.target.value)
    const filterData = Hours.filter((i,p)=> p === DataSelectNumber)

    
    setDataSelect(filterData)

  }
  


  
  function selectColl(event){
    setCollSelect(event.target.value)
  }

  
  const cores = [
    "#6A5ACD", 
    "#836FFF", 
    "#0890ff", 
    "#f4a131", 
    "#800000", 
    "#CD5C5C", 
    "#FF7F50",
    "#FF6347",
    "#FF8C00",
    "#DDA0DD",
    "#DA70D6",
    "#9370DB",
    "#8A2BE2",
    "#8B008B",
    "#006400",
    "#008000",
    "#6B8E23",
    "#808000",
    "#556B2F",
    "#20B2AA",
    "#008B8B",
    "#4682B4",
    "#B8860B",
    "#A52A2A",
    "#696969",
    "#778899",
    "#2F4F4F",
    "#A0522D",
    "#9f9b39",
    "#0087a8",
    "#00cb71",
    "#3c578e",
    "#1e3150",
    "#574116"
  ]

  let colors =  cores[Math.ceil(Math.random() * (cores.length - 1))];
 
 
  const onSubmit = async (data) => {

    //convertendo a data para yyyy/mm/dd
    const formatDataSelect = DataSelect.map(i => Object.keys(i)).toString()

    //convertendo a data para dia 0 = domingo, 1 = segunda
    const formatDay = parseInt(moment(formatDataSelect).format('d'))
    
    const formatDate = {
      name: data.name,
      tel: data.tel,
      ServicesId: ServiceIdSelected,
      CollaboratorsId: CollSelect,
      date: new Date(`${formatDataSelect}T${data.hora}`),
      days: formatDay,
      //UserId: ,
      color: colors,
    }

    try{

      if(userData.collaborator){
         await toast.promise(apiAgendaNaMao.post('agendamento',{
          name: formatDate.name,
          tel: formatDate.tel,
          ServicesId: formatDate.ServicesId,
          CollaboratorsId: CollSelect,
          date: formatDate.date,
          days: formatDay,
          UserId: userData.UserId,
          color: formatDate.color,
        }),{
          pending: 'Verificando seus dados',
          success: 'Agendamento realizado com sucesso!',
        })
        window.location.reload() 
      }

      await toast.promise(apiAgendaNaMao.post('agendamento',{
        name: formatDate.name,
        tel: formatDate.tel,
        ServicesId: formatDate.ServicesId,
        CollaboratorsId: CollSelect,
        date: formatDate.date,
        days: formatDay,
        UserId: userData.id,
        color: formatDate.color,
      }),{
        pending: 'Verificando seus dados',
        success: 'Agendamento realizado com sucesso!',
      }) 
      window.location.reload()
    }catch(err){
      const response = (err.response.data.error)
      toast.error( response,{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
    }
    
  }

  return(
      <Container>
        <Button style={{fontSize: '12px', color: 'green', fontWeight: 'bold'}} onClick={handleOpen}>Nova comanda</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Encaixe de cliente.
              </Typography>

              <ContainerItens>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>   
                  <p>Nome</p> 
                  <input defaultValue={list.props? list.props.name : ''} {...register("name",{required: true})}/>
                  <p>Tel</p> 
                  <input defaultValue={list.props? list.props.tel : ''} placeholder="(00) 00000-0000" type="tel" {...register("tel",{required: true})}/>
                  
                  <p>Serviço</p>
                  <select onChange={loadHous}>
                    <option value="" >Selecione</option>
                    {serviceFilter && serviceFilter.map(i => (
                      <option value={i.id} key={i.id}>{i.name}</option>
                    ))}
                  </select>

                  <p>Dia</p>
                  <select onChange={selectData}>
                    <option value="" >Selecione</option>
                    {formatData && formatData.map(i => (
                      <option value={i.posi} key={i.ano}>{i.ano}</option>
                    ))}
                  </select>

                  <p>Colaborador</p>
                  <select onChange={selectColl}>
                    <option value="" >Selecione</option>
                    {filterCollData && filterCollData.map(i => (
                      <option value={i.id} key={i.id}>{i.name}</option>
                    ))}
                  </select>

                  <p>Hora</p>
                  <select {...register("hora")}>
                    {teste && teste.map((i,p)=> (
                      <option key={p}>{i}</option>
                    ))}
                  </select>
                  <button type="submit">Salvar</button>
                  
                </form>
              </ContainerItens> 
            </Box>
        </Modal>
        
      </Container>
    )
}
import React from "react";
import Logo from '../../assets/Agenda na mão.png'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup"
import apiAgendaNaMao from "../../services/api";
import { toast } from 'react-toastify';
import { useUser } from "../../hooks/UserContext";
import { useNavigate } from "react-router-dom";
import whats from '../../assets/whatsapp.png'
import PWA from '../../assets/PWA agendaNaMao.png'
import swal from 'sweetalert';

import {
    Container,
    ContainerItens,
    ImgLogo,
    InputEmail, 
    InputSenha,
    ButtonEntrar, 
    //ButtonCreat,
    CreatConta,
    ContainerCreat,
    Error, } from './styles'
import paths from "../../constants/paths";

export function Login(){

    const {putUserData} = useUser()
    
    const navigate = useNavigate()

    const schema = Yup.object().shape({
        email: Yup.string().email('Email inválido').required('Email é obrigatório'),
        password: Yup.string().required('Senha é obrigatória').min(6, 'A senha deve ter pelo menos seis dígitos'),
    })
    
    
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmit = async clientData => {

        try{

            const {data} = await toast.promise(

                apiAgendaNaMao.post('sessions',{
                    email: clientData.email,
                    password: clientData.password
                }),{
                    pending: 'Verificando seus dados',
                    success: 'Login realizado com sucesso!',
                },
            )
            
            const detectarDispo = navigator.userAgent
            const iOS = detectarDispo.match(/iPhone|iPad|iPod/)
            const iosChome = navigator.userAgent.toLowerCase().includes('chrome')

            if((iOS && !window.navigator.standalone) || ( iOS && iosChome)){

                swal({
                    icon: `${PWA}`,
                    text: 'Para usar nosso sistema em seu Dispositivo iOS siga esse Passo a Passo.',
                    imageAlt: "A tall image",
                    buttons: {
                        text: "Voltar"
                    }
    
                }).then(()=>{
                    window.location.reload()
                })
                
            }
    
            navigator.serviceWorker.register('service-worker.js').then(async serviceWorker => {

                    let subscription = await serviceWorker.pushManager.getSubscription()
                        
                    subscription = await serviceWorker.pushManager.permissionState({
                        userVisibleOnly: true
                    }).then( async function(event){

                        if(event === 'prompt' || event === 'granted'){ 
                                
                                const publicKeyResponse = await apiAgendaNaMao.get('/push/public_key')
                               
                                try{

                                    subscription = await serviceWorker.pushManager.subscribe({
                                        userVisibleOnly: true,
                                        applicationServerKey: publicKeyResponse.data
                                    })
    
                                try{
                                      
                                    await apiAgendaNaMao.post('/push/register', {
                                        subscription: subscription,
                                        CollaboratorsId: data.id
                                    })
                                    
                                    navigate(paths.Agenda)
    
                                }catch(err){
                                    alert('Error ao enviar push ao banco de dados.')
                                }
    
                            }catch(err){
                                alert('Você nao vai recever notificações')
                                navigate(paths.Agenda)
                            }
                        }if( event === 'denied'){
                            navigate(paths.Agenda)
                        }
                }
                )
                    
            })
            
            putUserData(data)
      
        }catch(err){
            toast.error('Verique seu email e senha ou entre em contato com o suporte', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    function showHide(){
        const password = document.getElementById('pass')
        const icon = document.getElementById('icon')
        if(password.type === 'password'){
            password.setAttribute('type', 'text');
            icon.classList.add('hide')
        }else{
            password.setAttribute('type', 'password');
            icon.classList.remove('hide')
        }
    } 

    return(
        <Container>
            <ContainerItens>
                <ImgLogo alt="Agenda na Mão" src={Logo} />
                <h1>Login</h1>

                <form method="post" noValidate onSubmit={handleSubmit(onSubmit)}>
                <InputEmail placeholder="Email" type="email" {...register("email")}/>
                <Error>{errors.email?.message}</Error>

                
                <InputSenha autoComplete="of" placeholder="Senha" id="pass" type="password" {...register("password",)} />
                <Error>{errors.password?.message}</Error>
                <div id="icon" onClick={showHide}></div>

                <ButtonEntrar type="submit" to='/admin'>Entrar</ButtonEntrar>
                </form>

                <Link id="link" to="/backPass">Esqueceu a senha?</Link>
                
                <ContainerCreat>
                    <CreatConta>Não tem conta?</CreatConta>
                    <Link id="link" to="/cadastro" >Registrar-se</Link>
                </ContainerCreat>
                <a id="linkImgWhats" rel="noreferrer" target="_blank" href={`https://wa.me//5581992959030?text=Olá,%20gostaria de mais informações.`}><img alt="Suporte Whats" id="imgWhats" src={whats} /></a>
            </ContainerItens>
        </Container>

    
    )
}


import React from "react";
import Logo from '../../assets/Agenda na mão.png'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { Link } from "react-router-dom";
import apiAgendaNaMao from "../../services/api";
import { useNavigate } from "react-router-dom";
import paths from "../../constants/paths";
import { toast } from 'react-toastify';

import {
    Container,
    ContainerItens,
    ImgLogo,
    InputName,
    InputEstabelecimento,
    Select,
    InputTel,
    InputEmail, 
    InputSenha,
    InputSenhaConfirme,
    ButtonCreate,
    Error, 
  } from './styles'

export function Create(){

    const schema = Yup.object().shape({

        name: Yup.string().required('Nome é obrigatório'),
        estabelecimento: Yup.string().required('Estabelecimento é obrigatório'),
        cod: Yup.string().required('Código do pais é obrigatório'),
        tel: Yup.string().required('Número é obrigatório').min(11),
        email: Yup.string().required('Email é obrigatório').email(),
        password: Yup.string().required('Senha é obrigatória').min(6),
        confirmeSenha: Yup.string().required('Senha é obrigatória').oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
       /*  admin: Yup.boolean(), */

    })

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
      })

      const onSubmit = async (data) => {

        try{
            const response = await toast.promise(apiAgendaNaMao.post('users',{
                name: data.name,
                estabelecimento: data.estabelecimento,
                cod: data.cod,
                tel: data.tel,
                email: data.email,
                password: data.password
            }),{
                
                pending: 'Criando nova Conta',
                success: 'Conta Criada com sucesso!',    
                
            }) 
            
            navigate(paths.login)
           

            return response
           
        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

      }
    

    return(
        <Container>
            <ContainerItens>

                <ImgLogo src={Logo} />

                <h1>Cadastre-se</h1>

                <form noValidate onSubmit={handleSubmit(onSubmit)}  method="post" >

                <InputName autoComplete="off" placeholder="Nome" {...register("name")}/>
                <Error>{errors.name?.message}</Error>

                <InputEstabelecimento autoComplete="off" placeholder="Nome do Estabelecimento" {...register("estabelecimento")}/>
                <Error>{errors.estabelecimento?.message}</Error>

                <Select  {...register("cod")} >
                    <option>Brasil (+55)</option>
                    <option>Chile (+56)</option>
                    <option>Colômbia (+57)</option>
                    <option>Espanha (+34)</option>
                    <option>Estados Unidos (+1)</option>
                    <option>México (+52)</option>
                </Select>
                <Error>{errors.cod?.message}</Error>

                <InputTel autoComplete="off" placeholder="Telefone (DDD)XXXXXXXXX" type="tel" {...register("tel")}/>
                <Error>{errors.tel?.message}</Error>

                <InputEmail  placeholder="Email" type="email" {...register("email")} />
                <Error>{errors.email?.message}</Error>

                <InputSenha autoComplete="off" type="password" placeholder="Senha" {...register("password")}/>
                <Error>{errors.password?.message}</Error>

                <InputSenhaConfirme autoComplete="off" type="password" placeholder="Confime a senha" {...register("confirmeSenha")}/>
                <Error>{errors.confirmeSenha?.message}</Error>

                <ButtonCreate type="submit" >Criar</ButtonCreate>
                </form>

                <div style={{display: "flex", marginTop: 10}}>
                <p style={{fontSize: 14, marginRight: 5}} >Já possui cadastro?</p>
                <Link   style={{fontSize: 14,}} to="/" >Entrar</Link>
                </div>

            </ContainerItens>
        </Container>

    )
}


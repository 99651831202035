import React, {createContext, useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'

const ServiceContext = createContext([])

export const ServiceProvider = ({children}) =>{

    const [services, setServices] = useState([])

    const putServiceData = async (serviceInfor) => {
        setServices(serviceInfor)

        await localStorage.setItem('agendanamao:serviceData', JSON.stringify(serviceInfor))

    }

    useEffect(()=>{

        const loadServiceData = async () =>{

            const serviceInfor = await localStorage.getItem('agendanamao:serviceData')

            if(serviceInfor){
                setServices(JSON.parse(serviceInfor))
            }
        }
        loadServiceData()
    },[])

    return(
        <ServiceContext.Provider value={{putServiceData, services}}>
            {children}
        </ServiceContext.Provider>
    )
}

export const useService = () => {

    const context = useContext(ServiceContext)

    if(!context){
        throw new Error('useService deu error')
    }

    return context
}

ServiceProvider.propTypes = { 
    children: PropTypes.node
} 
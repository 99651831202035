import { useEffect, useState } from "react"
import {useUser} from '../../hooks/UserContext'
import { useNavigate } from "react-router-dom";
import paths from "../../constants/paths";
import apiAgendaNaMao from "../../services/api";
import swal from 'sweetalert';

export function Notifications(){


    const {userData} = useUser()
    const [ListNotifications,setListNotifications ] = useState([])
    const [FilterNotifications,setFilterNotifications ] = useState([])
    const navigate = useNavigate()
    
    
    useEffect(()=>{
        async function LoadsRecs(){
            const {data: listNotification} = await apiAgendaNaMao.get('/push/list_public_key')
            setListNotifications(listNotification)
        }
        LoadsRecs()
        
    },[])
    
    useEffect(()=>{
        const filterNotification = ListNotifications.filter( i => i.CollaboratorsId === userData.id).map(i => i.CollaboratorsId)
        setFilterNotifications(filterNotification)
    },[userData.id,ListNotifications])


    async function deleteNotification(){
        const formatCollId = FilterNotifications.toString()
        await apiAgendaNaMao.delete(`/push/delete/${formatCollId}`)
    }


    useEffect(()=>{
        
        navigator.serviceWorker.register('service-worker.js').then(async serviceWorker => {
            //prompt = iniciar (foi enviado a solicitação de notificação)
            //granted = Garantido (foi aceita notificação)
            //denied = Negado (foi negado ou desativado a notificação)

            let subscription = await serviceWorker.pushManager.getSubscription()

            subscription = await serviceWorker.pushManager.permissionState({
                userVisibleOnly: true
            }).then( async function(event){

                if(event === 'denied' && FilterNotifications.length > 0){
                    deleteNotification()
                }
                
                if((event === 'prompt') || (event ==='granted' && subscription === null)){
                    swal({
                        title: "Redefinir as Notificações",
                        text: "Para redefinir as notificações pressione em 'Realizar Login'.",
                        buttons: {
                            text: "Relizar Login"
                        }
                    }).then(()=>{
                        
                        navigate(paths.login)
                            
                    })
                }
            }
            )
        })
    },[userData.id, FilterNotifications, userData.collaborator])

}


const listPagamentos = [
    {
        id: 1,
        label: "Selecione",
        value: "Não escolhido"
    },
    {
        id: 2,
        label: "Á vista",
        value: "Á vista"
    },
    {
        id: 3,
        label: "Á vista + Desconto",
        value: "Á vista + Desconto"
    },
    {
        id: 4,
        label: "Cartão",
        value: "Cartão"
    },
    {
        id: 5,
        label: "Pix",
        value: "Pix"
    },
    {
        id: 6,
        label: "Pix + Desconto",
        value: "Pix + Desconto"
    },
]

export default listPagamentos
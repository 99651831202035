import React, { useLayoutEffect, useRef, useState } from "react";
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import LoginIcon from '@mui/icons-material/Login';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import {
    ContainerPrincipal,
    Header,
    Main,
    ImgAppStore,
    ImgPlayStore,
    ImgLogoHeader,
    MainSobre,
    ImgFuncionalidade,
    MainPlanos,
    Footer,
    ImgFuncionalidadePrecificacao,
    ImgIconeWhats
} from './styles'

import AppStore from '../../assets/AppStorepng.png'
import PlayStore from '../../assets/googleplaypng.png'
import logo from '../../assets/Agenda logo png.png'
import logoBranca from '../../assets/Agenda logoBranco png.png'


import imgTelaAgenda from '../../assets/telaAgenda.png'
import imgTelaRelatorio from '../../assets/telaRelatorio.png'
import imgTelaClientes from '../../assets/telaClientes.png'
import imgTelaPrecificacao from '../../assets/telaPrecificacao.png'
import imgIconeWhats from "../../assets/whatsapp.png";

export function Home() {

    const el = useRef()
    const tl = useRef()
    const [screm, setScrem] = useState('anual')
    const [buttonActive, setButtonActive] = useState('orange')
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.to(".container-inicio", {
            x: 0,
            opacity: 1,
            scrollTrigger: {
                trigger: ".main1",
                start: "top 1px",
                end: "bottom 320px",
                //markers: true

            }
        })

        return () => {
            gsap.killTweensOf(".container-inicio")
        }
    }, [])

    useLayoutEffect(() => {
        gsap.registerEffect(ScrollTrigger);

        gsap.context(() => {
            tl.current = gsap.timeline({
                scrollTrigger: {
                    trigger: "#itens",
                    scrub: true,
                    //markers: true,
                    start: "top 97%",
                    end: "bottom 100%"
                }
            })
                .fromTo(".div-carrosel-funcionalidades01", {
                    opacity: 0,
                    y: 160,

                }, {
                    opacity: 1,
                    y: 0
                })
                .fromTo(".div-carrosel-funcionalidades02", {
                    opacity: 0,
                    y: 160,

                }, {
                    opacity: 1,
                    y: 0
                })
                .fromTo(".div-carrosel-funcionalidades03", {
                    opacity: 0,
                    y: 160,

                }, {
                    opacity: 1,
                    y: 0
                })
                .fromTo(".div-carrosel-funcionalidades04", {
                    opacity: 0,
                    y: 160,

                }, {
                    opacity: 1,
                    y: 0
                })
                .fromTo(".div-carrosel-funcionalidades05", {
                    opacity: 0,
                    y: 160,

                }, {
                    opacity: 1,
                    y: 0
                })

        }, el)


        return () => {
            gsap.killTweensOf("#itens")
        }
    }, [])

    return (
        <>
            <ContainerPrincipal >
                <div className="icone-whats-flutuante">
                    <a href={`https://wa.me//5581992959030?text=Olá, gostaria de mais informações.`} rel="noreferrer" target="_blank">
                        <ImgIconeWhats src={imgIconeWhats}/>
                    </a>
                </div>
                <Header >
                    <div className="logo">
                        <ImgLogoHeader src={logo} />
                    </div>

                    <div className="menu">
                        <nav className="nav-menu">
                            <a href="#main1">INÍCIO</a>
                            <a href="#main2">FUNCIONALIDADES</a>
                            <a href="#main3">PLANOS</a>
                            <a href="#main4">CONTATO</a>
                        </nav>
                    </div>

                    <div className="login">
                        <LoginIcon />
                        <a href="https://agendanamao.com/login">LOGIN</a>
                    </div>
                </Header>
            </ContainerPrincipal>

            <Main className="main1" id="main1">
                <div className="container-inicio">
                    <div className="conteudo-inicio">
                        <div className="titulo-inicio">
                            <h1 className="titulo-agendanamao">AgendaNaMão</h1>
                            <h1 className="destaque-inicio"></h1>
                        </div>
                        <div className="paragrafo-inicio" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                            <p className="titulo-descricao">Nunca foi tão fácil receber Agendamentos online e Gerenciar seu espaço.</p>
                            <p className="titulo-baixeAgora">Baixe e experimente grátis por 7 dias.</p>
                        </div>
                    </div>

                    <div className='div-baixarApp'>
                        <ImgAppStore src={AppStore} />
                        <ImgPlayStore src={PlayStore} />
                    </div>
                </div>
            </Main>

            <MainSobre className="main2" id="main2" ref={el}>
                <div className="div-titulo-inicial-MainSobre">
                    <p className="titulo-principal-mainSobre">O sistema ideal para executar suas Atividades.</p>
                    <p className="titulo-secundario-mainSobre">Com o AgendaNaMão, você otimiza seu tempo e descomplica a gestão do seu espaço.</p>
                </div>

                <div className="div-carrosel-funcionalidades">

                    <div id="itens" className="div-carrosel-funcionalidades01">
                        <ImgFuncionalidade src={imgTelaAgenda} />
                        <p className="titulo-Tela">Agendamentos</p>
                        <p className="descricao-funcionalidades">Tenha total controle da sua agenda na palma da sua mão.</p>
                    </div>

                    <div id="itens" className="div-carrosel-funcionalidades02">
                        <ImgFuncionalidade src={imgTelaRelatorio} />
                        <p className="titulo-Tela">Relatório financeiro</p>
                        <p className="descricao-funcionalidades">Acompanhe de perto os resultados do seu negócio e otimize a sua saúde financeira e dos seus profissionais.</p>
                    </div>

                    <div id="itens" className="div-carrosel-funcionalidades03">
                        <ImgFuncionalidade src={imgTelaClientes} />
                        <p className="titulo-Tela">Cadastro de clientes</p>
                        <p className="descricao-funcionalidades">Construa um relacionamento com seus clientes e aumente seu faturamento.</p>
                    </div>

                    <div id="itens" className="div-carrosel-funcionalidades04">
                        <ImgFuncionalidadePrecificacao src={imgTelaPrecificacao} />
                        <p className="titulo-Tela">Precificação de serviços</p>
                        <p className="descricao-funcionalidades">Calcule o preço ideal dos seus serviços e garanta a sobrevivência do seu negócio</p>
                    </div>
                    <div id="itens" className="div-carrosel-funcionalidades05">
                        <p>E muito mais...</p>
                    </div>

                </div>
            </MainSobre>

            <MainPlanos className="main3" id="main3">
                <div className="plano-preco">
                    <h1>Planos e preço</h1>
                    <p className="descricao-planos">Conheça nossos planos e escolha o melhor de acordo com o seu negócio. </p>
                </div>

                <div className="button-mes-ano">
                    {screm === 'mensal' ? <button style={{ backgroundColor: buttonActive }} onClick={() => setScrem('mensal')} className="mensal">Mensal</button> : <button onClick={() => setScrem('mensal')} className="mensal">Mensal</button>}
                    {screm === 'anual' ? <button style={{ backgroundColor: buttonActive }} onClick={() => setScrem('anual')} className="anual">Anual <p className="anual-off">28% off</p></button> : <button onClick={() => setScrem('anual')} className="anual">Anual <p className="anual-off">28% off</p></button>}

                </div>
                {screm === 'mensal' && (
                    <div className="planos">

                        <div className="plano-basico">
                            <h3>Plano básico</h3>
                            <p className="descricao-plano">Ideal para negócios que possuem um profissional.</p>
                            <p className="preco-mes">R$25,00/mês</p>

                            <hr></hr>

                            <div className="div-descricao-plano">
                                <p>  <CheckIcon style={{ color: 'green' }} />Cadastro limitado de profissionais</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Administre sua agenda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Relatório financeiro</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Agendamentos limitado</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Caixa/comanda</p>
                            </div>

                            <div className="div-button-adesao">
                                <a href={`https://wa.me//5581992959030?text=Olá, gostaria de me assinar no plano Mensal básico.`} rel="noreferrer" target="_blank">
                                    <button >Teste grátis por 7 dias</button>
                                </a>
                                
                            </div>
                        </div>

                        <div className="plano-pro">
                            <h3>Plano pro</h3>
                            <p className="descricao-plano">Ideal para negócios que possuem mais de um profissional.</p>
                            <p className="preco-mes">R$35,00/mês</p>

                            <hr></hr>

                            <div className="div-descricao-plano">
                                <p>  <CheckIcon style={{ color: 'green' }} /> Cadastro ilimitado de profissionais</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Administre sua agenda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Relatório financeiro</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Agendamentos ilimitados</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Caixa/comanda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Precificação de serviços</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Cadastro de clientes</p>
                            </div>

                            <div className="div-button-adesao">
                                <a href={`https://wa.me//5581992959030?text=Olá, gostaria de me assinar no plano Mensal Pro.`} rel="noreferrer" target="_blank">
                                 <button>Teste grátis por 7 dias</button>
                                </a>
                                
                            </div>
                        </div>

                    </div>
                )}

                {screm === 'anual' && (
                    <div className="planos">

                        <div className="plano-basico">
                            <h3>Plano básico </h3>
                            <p className="descricao-plano">Ideal para negócios que possuem um profissional.</p>
                            <p className="preco-mes">R$216,00/ano </p>
                            <p className="preco-mes-reducao"> <strike>R$300,00/ano</strike></p>
                            <p className="economia">Pague de uma só vez e economize R$ 84,00 </p>

                            <hr></hr>

                            <div className="div-descricao-plano">
                                <p>  <CheckIcon style={{ color: 'green' }} />Cadastro limitado de profissionais</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Administre sua agenda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Relatório financeiro</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Agendamentos limitado</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Caixa/comanda</p>
                            </div>

                            <div className="div-button-adesao">
                                <a href={`https://wa.me//5581992959030?text=Olá, gostaria de me assinar no plano Anual básico.`} rel="noreferrer" target="_blank">
                                    <button>Teste grátis por 7 dias</button>
                                </a>
                               
                            </div>
                        </div>

                        <div className="plano-pro">
                            <h3>Plano pro</h3>

                            <p className="descricao-plano">Ideal para negócios que possuem mais de um profissional.</p>

                            <p className="preco-mes">R$300,00/ano </p>
                            <p className="preco-mes-reducao"> <strike>R$420,00/ano</strike></p>
                            <p className="economia">Pague de uma só vez e economize R$ 120,00 </p>
                            <hr></hr>

                            <div className="div-descricao-plano">
                                <p>  <CheckIcon style={{ color: 'green' }} /> Cadastro ilimitado de profissionais</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Administre sua agenda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Relatório financeiro</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Agendamentos ilimitados</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Caixa/comanda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Precificação de serviços</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Cadastro de clientes</p>
                            </div>

                            <div className="div-button-adesao">
                                <a href={`https://wa.me//5581992959030?text=Olá, gostaria de me assinar no plano Anual Pro.`} rel="noreferrer" target="_blank">
                                    <button>Teste grátis por 7 dias</button>
                                </a>
                                
                            </div>
                        </div>

                    </div>
                )}
            </MainPlanos>

            <Footer className="main4" id="main4">
                <div className="container-footer">

                    <div className="footer-column-logo-column">
                        <ImgLogoHeader src={logoBranca} />
                    </div>

                    <div className="footer-column-infor-column">
                        <h2>Contato / Suporte</h2>
                        <p><PhoneAndroidIcon/> 55 81 99295-9030</p>
                        <p><EmailIcon /> agendanamaooficial@gmail.com</p>
                    </div>

                    <div className="footer-column-social-column">
                        <p>Baixe nosso app</p>
                        <ImgAppStore src={AppStore} />
                        <ImgPlayStore src={PlayStore} />
                    </div>

                </div>
                <br/>
                    <hr></hr>
                <br/>
                <div className="footer-column-credits-column">
                        <p>&copy; 2024 Todos os direitos reservados. </p>
                        <p> Desenvolvido por Alex Silva.</p>
                        <a href="https://agendanamao.com/politica-privacidade" rel="noreferrer" target="_blank">
                            <p>Política de privacidade</p>
                        </a>
                </div>
                <br/>
            </Footer>
        </>
    )

}
import styled from "styled-components";

export const Container = styled.div`
    
    width: 100vw;
    
    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }
    
    .Pagname{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 90px;
    }

    #divCarroselPrincipal{
      
        display: grid;
        grid-template-columns: 100px 1fr;
        padding-top: 10px;
       
        #divPhoto{
            display: flex;
            align-items: center;

            img{
                width: 90px;
            }
        }

        #divItensCarrosel{
            display: flex;
            flex-direction: column;
            background-color: #FFF8DC;
            padding-top: 5px;
            width: 90%;
            padding-bottom: 5px;
            padding-left: 10px;
            border-radius: 10px;
        
            h3{
                margin-bottom: 5px;
                display: flex;
                align-items: center;
            }

            h6{
                margin-bottom: 5px;
                display: flex;
                align-items: center;
            }

            #divButtonDeleteEdite{
                display: flex;
                gap: 20px;
                width: 100px;

                #buttonDelete{
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    :hover{
                        background-color: red;
                    }
                }
                
            }
        } 

    }

`

export const ContainerItens = styled.div`
    background-color: white;
    margin-top: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    
`










import styled from "styled-components";

export const Container = styled.div`
    display: flex;
 
    #divMenu{
        display: flex;
        align-items: center;
    }

    #divLogout{
        display: flex;
        position: absolute;
        right: 29px;
    }

    #long-menu{
        
    }
   
`
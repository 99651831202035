import styled from "styled-components";

export const Container = styled.div`

    width: 100vw;

    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }

`

export const ContainerItens = styled.div`

    display: flex;
    flex-direction: column;
    margin-top: 40px;

    p{
        margin-top: 5px;
        margin-left: 12px;
    }

    #divSuportWhats{
       
        display: flex;
        
        a{
            display: flex;
            margin-top: 7px;
            margin-left: 12px;
        }
      
    }

`
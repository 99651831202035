import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  
    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }

    .Pagname{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 90px;
    }

    #divCarroselPrincipal{
  
        width: 300px;
        background-color: #FFF8DC;
        border-radius: 20px;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
        border: 1px solid black;
        margin-bottom: 15px;

        @media(min-width: 1050px){
            margin-right: 20px;
            margin-left: 20px;
        }

        @media(max-width: 400px){
            width: 200px;
        }


        #divItens{
            display: flex;
            align-items: center;
            flex-direction: column;

            h4{
                font-size: 15px;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                display: flex;
                align-items: center;

                @media(max-width: 400px){
                    font-size: 11px;
                }

                @media(max-width: 450px){
                    font-size: 12px;
                }
            }
            h6{
                font-size: 12px;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                display: flex;
                align-items: center;

                @media(max-width: 400px){
                    font-size: 10px;
                }

                @media(max-width: 450px){
                    font-size: 11px;
                }
            }
        }
    }



    #divButtonDeleteEdite{
       display: flex;
       margin-top: 14px;
       gap: 25px;
    }
    
`

export const ContainerItens = styled.div`
    
    margin-top: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    height: 500px;

    #buttonDelete{
        border: none;
        background: transparent;
        cursor: pointer;

        &:hover{
            opacity: 0.8;
            background: red;
            color: #fff;
        }

        &:active{
            opacity: 0.6;
        }
    }

    #divItensPrincipal{
       display: flex;
       gap: 5px;
       flex-direction: column;
       margin-top: 32px;
       margin-left: 4px;
       width: 220px;
       background-color: #DCDCDC;
       padding-bottom: 14px;
       border-bottom-right-radius: 10px;
       border-bottom-left-radius: 10px;
      
        p{
            font-size: 14px;
            font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            padding-left: 8px;
            display: flex;
            align-items: center;
            justify-content: left;
        }

        #divEditeDelete{
            display: flex;
            align-items: center;
            justify-content: right;
            background-color: #F4A460;
            height: 20px;
            
        }

        select{
            margin-left: 5px;
            background-color: transparent;
            border-radius: 5px;
        }
    }

`

export const ButtonEdite = styled.button`
 width: 50px;

`








import React from "react";
import {Navigate} from "react-router-dom";

import PropTypes from 'prop-types'

function PrivateRoute({ children, redirectTo,}){

    const user = localStorage.getItem('agendanamao:userData')

    if(!user){
        return <Navigate to={redirectTo}/>
    }

     return children
     
}


export default PrivateRoute

PrivateRoute.propTypes = {
    element: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
}
import React, {useState} from "react";
import Logo from '../../assets/Agenda na mão.png'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toast } from 'react-toastify';
import apiAgendaNaMao from "../../services/api";
import whats from '../../assets/whatsapp.png'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
    Container,
    ContainerItens,
    ImgLogo,
    InputEmail,
    ButtonEviar,
    Error
} from './styles'

export function BackupPass(){

    const schema = yup.object().shape({
        email: yup.string().required('Email é obrigatório')
    })

    const [StatusRec, setStatusRec] = useState(0)
    const [DateUserColl, setDateUserColl] = useState(0)
    const [NewPass, setNewPass] = useState([])

    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })
  

    const onSubmit = async infor => {


        try{

            const data = await toast.promise(
                apiAgendaNaMao.post('/recoverPass',{
                    email: infor.email
                }),{
                    pending: 'Verificando seus dados',
                    success: 'E-mail encontado com sucesso!',
                }
            )
            setDateUserColl(data.data)

            if(data.status){
                setStatusRec(data.status)
            }
            
        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }

    function passW(i){
        setNewPass(i.target.value)
    }

    async function updatePass(){

        if(DateUserColl.collaborator){ 
            try{
                await toast.promise(apiAgendaNaMao.put(`/update/${DateUserColl.buscarContaColl.id}`,{
                    password: NewPass
                }),{
                    pending: 'Verificando seus dados',
                    success: 'Senha alterada com sucesso!',
                })
    
                navigate('/')
    
            }catch(err){
                alert(err.message)
            }
           
        }

        try{
            await toast.promise(apiAgendaNaMao.put(`/update/${DateUserColl.id}`,{
                password: NewPass
            }),{
                pending: 'Verificando seus dados',
                success: 'Senha alterada com sucesso!',
            })

            navigate('/')

        }catch(err){
            alert(err.message)
        }
       
    }


    return(
        <Container>
            <ContainerItens>
                <ImgLogo  src={Logo}/>
                <h1>Recuperar Senha</h1>
                {StatusRec === 0 && (
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <InputEmail type="email" placeholder="E-mail" {...register("email")} />
                        <Error>{errors.email?.message}</Error>
                        <ButtonEviar type="submit">Enviar</ButtonEviar>
                        <Link to="/">Login</Link>
                    </form>
                )}
                {StatusRec === 200&& (
                    <div>
                        <p>Digite a nova Senha</p>
                        <input  onChange={passW} type="password"/>
                        <button onClick={updatePass} >Salvar</button>
                    </div>
                )}
                <a id="linkImgWhats" rel="noreferrer" target="_blank" href={`https://wa.me//5581992959030?text=Olá,%20gostaria de mais informações.`}><img alt="Suporte Whats" id="imgWhats" src={whats} /></a>
            </ContainerItens>   
        </Container>
    )

}
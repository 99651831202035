import styled from "styled-components";

export const Container = styled.div`

    width: 100vw;
    
    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }

    #divemDev{
        display: flex;
        align-items: center;
        margin-top: 50px;

        img{
            width: 80vw;
        }
    }

`
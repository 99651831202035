import React from "react";
import {useState} from 'react'
import Modal from "react-modal";
import * as Yup from "yup"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import apiAgendaNaMao from "../../services/api";
import { toast } from 'react-toastify';
import {useUser} from '../../hooks/UserContext'

import {
    Container,
    ContainerItens,
    InputName,
    InputPrice,
    InputTime,
    Div,
    Error,
    ContainerNavModal 
    
} from "./styles"

Modal.setAppElement("#root");

export function ModalService(){

 const [modalService, setService] = useState(false)
 const [Colors, setColors] = useState([])


 const {userData} = useUser({})
 
 const schema = Yup.object().shape({

    name: Yup.string().required('Campo obrigatório'),
    price: Yup.number().required('Campo obrigatório'),
    time: Yup.string().required('Campo obrigatório'),

 })

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

 
    function ColorsAleatory(){

        const cores = [
            "#6A5ACD", 
            "#836FFF", 
            "#191970", 
            "#000080", 
            "#800000", 
            "#CD5C5C", 
            "#FF7F50",
            "#FF6347",
            "#FF8C00",
            "#DDA0DD",
            "#DA70D6",
            "#9370DB",
            "#8A2BE2",
            "#8B008B",
            "#006400",
            "#008000",
            "#6B8E23",
            "#808000",
            "#556B2F",
            "#20B2AA",
            "#008B8B",
            "#4682B4",
            "#B8860B",
            "#A52A2A",
            "#696969",
            "#778899",
            "#2F4F4F",
            "#A0522D",
        ]

        const colors =  cores[Math.ceil(Math.random() * (cores.length - 1))];
        setColors(colors)
   }
  
    const onSubmit = async data => {
        
         try{

            if(userData.collaborator){ 

                
                await toast.promise(apiAgendaNaMao.post('service', {
                    name: data.name,
                    price: parseInt(data.price),
                    time: new Date(`2024-02-02T${data.time}`), 
                    UserId: userData.UserId,
                    color: Colors
                }),{
                    pending: 'Verificando seus dados',
                    success: 'Serviço Cadastrado com sucesso!',
                })

                window.location.reload()
            }else{

                
    
                await toast.promise(apiAgendaNaMao.post('service', {
                    name: data.name,
                    price: parseInt(data.price),
                    time: new Date(`2024-02-02T${data.time}`),
                    UserId: userData.id,
                    color: Colors
                }),{
                    pending: 'Verificando seus dados',
                    success: 'Serviço Cadastrado com sucesso!',
                })
                window.location.reload()
            }
             

            closeModal()

        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    //função para quando for aciosado vai abrir a modal
    function openModal(){
        setService(true)
    }

    //função para quando for aciosado vai fechar a modal
    function closeModal(){
        setService(false)
    }

    return(
        
        <Container>
            <Div>
                <button onClick={openModal}>Adicionar Serviço</button>
            </Div>

            <Modal
                isOpen={modalService}
                onRequestClose={closeModal}
                contentLabel="exemplo modal"
                overlayClassName="modal-overlay"
                className="modal-content"
                >
                <ContainerItens>
                    
                    <ContainerNavModal>
                        <label id="nameHead">Adicionar Serviço</label>
                        <button id="buttonExit" onClick={closeModal}>X</button>
                    </ContainerNavModal>

                    <form noValidate onSubmit={handleSubmit(onSubmit)} method="post" > 

                        <p>Nome</p>
                        <InputName placeholder="Nome do Serviço" {...register("name")} />
                        <Error>{errors.name?.message}</Error>
                        <p>Preço</p>
                        <InputPrice type="number" placeholder="Preço do Seviço" {...register("price")} />
                        <Error>{errors.price?.message}</Error>
                        
                        <p>Tempo</p>
                        <InputTime type="time" {...register("time")}/>
                        <Error>{errors.time?.message}</Error>

                        <div id="divsubmit">
                            <button  onClick={ColorsAleatory} type="submit" id="buttonSave">Cadastrar</button>
                        </div>
                    </form>

                </ContainerItens>

            </Modal>
            
        </Container>
    )

}
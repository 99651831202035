import React from "react";
import {useState} from 'react'
import Modal from "react-modal";
import * as Yup from "yup"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import EditNoteIcon from '@mui/icons-material/EditNote';
import apiAgendaNaMao from "../../services/api";
import { toast } from 'react-toastify';
import moment from "moment";

import {
    Container,
    ContainerItens,
    InputName,
    InputPrice,
    InputTime,
    Div,
    Error,
    ContainerNavModal,
    InputActive
} from "./styles"


Modal.setAppElement("#root");

export function ModalEditeService({children}){

    
    let dates = moment(children.time).format("HH:mm")

    const schema = Yup.object().shape({
        name: Yup.string(),
        price: Yup.number(),
        time: Yup.string(),
        active: Yup.string(),
    })

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async data => {

        try{
            await toast.promise(apiAgendaNaMao.put(`/service/${children.id}`, {
                name: data.name,
                price: data.price,
                time: new Date(`2024-02-02T${data.time}`), 
                active: data.active,
            }),{
                pending: 'Verificando seus dados',
                success: 'Serviço Atualizado com Sucesso!',
            }) 

            closeModal()
            window.location.reload()

        }catch(err){

            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } 
        
    }

    const [modalEditeService, setEditeService] = useState(false)

    //função para quando for aciosado vai abrir a modal
    function openModal(){
        setEditeService(true)
    }

    //função para quando for aciosado vai fechar a modal
    function closeModal(){
        setEditeService(false)
    }

    return(
        <Container>
        
            <Div> 
                <button onClick={openModal} > <EditNoteIcon style={{width: '25px',marginTop: "9px"}}/></button>
            </Div>
        
            <Modal
                isOpen={modalEditeService}
                onRequestClose={closeModal}
                contentLabel="exemplo modal"
                overlayClassName="modal-overlay"
                className="modal-content"
                >
                
                <ContainerItens>

                    <ContainerNavModal>
                        <label id="nameHead">Editar Serviço</label>
                        <button id="buttonExit" onClick={closeModal}>X</button>
                    </ContainerNavModal>
            
                    <form noValidate onSubmit={handleSubmit(onSubmit)} method="put" > 

                        <label>Nome</label>
                        <InputName defaultValue={children.name} type="" placeholder="Nome" {...register("name")} />
                        <Error>{errors.name?.message}</Error>

                        <label>Preço</label>
                        <InputPrice defaultValue={children.price} type="number" placeholder="Preço" {...register("price")} />
                        <Error>{errors.price?.message}</Error>

                        <label>Tempo</label>
                        <InputTime  defaultValue={dates} type="time" placeholder="Duração do Serviço" {...register("time")}/>
                        <Error>{errors.time?.message}</Error>

                        <label>Ativo?</label>
                        <InputActive {...register("active")} defaultValue={children.active}>
                                <option value={'Sim'}>Sim</option>
                                <option value={'Não'}>Não</option>
                        </InputActive>
                        <Error>{errors.active?.message}</Error>

                        <div id="divsubmit">
                            <button type="submit" id="buttonSave">Atualizar</button>
                        </div>
                    </form>

                </ContainerItens>
            
            </Modal>
        
        </Container>
    )
}
import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    

    /*  @media (max-width: 1100px) {
        width: 50vh;
    } */
    /* 
    @media (max-width: 1378px) {
        width: 90vh;
    }   */   

 h1{
    margin-left: 3vh;
    margin-top: 2vh;
    font-size: 17px;
   }

   

   #modalBlockAgenda{
    position: fixed;
    bottom: 10px;
    right: 10px;
   }

`

export const ContainerCalendar = styled.div`
    margin-top: 5vh;
    display: flex;
    align-items: center;
    flex-direction: column;

    /* @media (max-width: 1627px) {
        width: 100vh;
    }

    @media (max-width: 1378px) {
        width: 90vh;
    } */

    .calendar{
        display: flex;
        font-family: Arial, Helvetica, sans-serif, Helvetica, sans-serif;
        margin-top: 1vh;
        min-height: 100vh;
        min-width : 90%;
        border-radius: 8px;
        background: #FFFAFA;

        ::first-letter{  //Letras iniciais em maiúsculo
            text-transform: uppercase;
        }

        @media(max-width: 376px){
            font-size: 12px;
        }
    
    }

    select{

        padding-left: 5px;
        width: 230px;
        height: 4vh;
        font-size: medium;
        background-color: #3498db;
        color: #fff;
        border: none;
        border-radius: 5px;
        box-shadow: 1px 1px 1px #202020;
        font-weight: bolder;
        cursor: pointer;

        &:hover{
            opacity: 0.8;

        }

        &:active{
            opacity: 0.6;
        }

        @media(max-width: 376px){
            width: 130px;
            font-size: 14px;
        }

        @media(max-width: 690px){
            width: 200px;
            font-size: 13px;
        }
     
    }

    p{
        font-weight: bolder;
        font-size: large;
    }


`





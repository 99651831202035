import styled from "styled-components";

export const Container = styled.div`

   width: 90vw;

    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }
`

export const ContainerCalendar = styled.div`

    overflow: scroll;
    margin-top: 30px;

    .calendar{
        font-family: Arial, Helvetica, sans-serif, Helvetica, sans-serif;
        margin-top: 1vw;
        font-size: 11px;
        background: #FFFAFA;

        ::first-letter{  //Letras iniciais em maiúsculo
            text-transform: uppercase;

        }

        @media (max-width: 667px) {
            width: 90vh;
        } 
        
    }

    #divNav{
        display: flex;
        width: 300px;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;


        select{
            background-color: #3498db;
            color: #fff;
            border: none;
            border-radius: 5px;
            box-shadow: 1px 1px 1px #202020;
            font-weight: bolder;
            cursor: pointer;
            width: 150px;
            height: 20px;
        }
    }
`
import React, {createContext, useContext, useState, useEffect} from "react";
import PropTypes from 'prop-types'

// 01 - Criando o context
const UserContext = createContext({}) 

// 02 - Criando o provider
export const UserProvider = ({ children }) => {  

    // 03 - Criando um stado para guardar novas informações 
    const [userData, setUserData] = useState({}) 

    // 04 - Criar função para quando chamar ela vai gravar dentro do state as informações que chegar
    const putUserData = async userInfo =>{  
        setUserData(userInfo)  
        //Gravando dados no local storage e convertendo para string
        await localStorage.setItem('agendanamao:userData', JSON.stringify(userInfo)) 
    }



    //Criando useEffect para quando aplicação iniciar ela vai recuperar os dados do local storage
    useEffect(() => {  

        // criando função assicrona porque useEffect não permite crirar nele
        const loadUserData = async () => {

            // const para armazenar as informações que vão vim do local st
            const clientInfo = await localStorage.getItem('agendanamao:userData')
                        
            // verificando se existe informações do usuario, se tiver recupera os dados e convertendo os dados de volta para objeto
            if(clientInfo){
                setUserData(JSON.parse(clientInfo)) 
            }
        }

        // chamando função
        loadUserData() 
    }, [])


    async function logout(){
        await localStorage.removeItem('agendanamao:userData')
    }
        
    //02 - return do provider
    return(   
        <UserContext.Provider value={{putUserData, userData, logout }}>
            {children}
        </UserContext.Provider>
    )
}

//05 - Criando o useContex para disponibilizar para toda aplicação
export const useUser =() =>{

    const context = useContext(UserContext)

    //se não existir dados vai chamar esse error
    if(!context){
        throw new Error("useUser must be user with UserContext")
    }

    return context
}

UserProvider.propTypes = { 
    children: PropTypes.node
}

 const intervalAgenda = [
 {
    id: 1,
    label: 10,
    value: 10,
 },

 {
    id: 2,
    label: 15,
    value: 15,
 },
 {
    id: 3,
    label: 20,
    value: 20,
 },
 {
    id: 4,
    label: 30,
    value: 30,
 },
 {
    id: 5,
    label: 40,
    value: 40,
 },
 {
    id: 6,
    label: 50,
    value: 50,
 },
 {
    id: 7,
    label: 60,
    value: 60,
 },
 {
    id: 8,
    label: 90,
    value: 90,
 },
 {
    id: 9,
    label: 120,
    value: 120,
 },
]

export default intervalAgenda
import React, {useEffect, useState} from "react";
import apiAgendaNaMao from "../../../services/api";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Container,ContainerItens} from "./styles";
import {ModalCollaborator} from '../../../components/ModalCollaborator'
import {useContextColl} from '../../../hooks/CollaboratorContext'
import {useUser} from '../../../hooks/UserContext'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ModalEditeColla } from "../../../components/ModalEditeColla";
import { Carousel } from 'primereact/carousel';
import { toast } from 'react-toastify';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export function Collaborator(){

  const [collaborator, setCollaborator] = useState([])
  const [filterColl, setFilterColl ] = useState([])
  const {putCollData} = useContextColl({})
  const {userData} = useUser({})

  useEffect(()=>{
  
    async function loadCollaborator(){

      const {data} = await apiAgendaNaMao.get('collaborator')

      setCollaborator(data)
      putCollData(data) 
    }
    loadCollaborator()
    
  },[]) 


  useEffect(()=>{
  
    const filterCollaborator = collaborator.filter(coll => coll.UserId === userData.id || coll.UserId === userData.UserId)
    
    setFilterColl(filterCollaborator)
    productTemplate(filterCollaborator)
  },[collaborator, userData.id, userData.UserId])   

  function Moderator(coll){ 
    if(coll === true){
      return <CheckIcon style={{color: '#006400'}}/>
    }
    return <CloseIcon style={{color: 'red'}}/>
  }

  async function deleteColl(coll){
    
    if(window.confirm(`Tem certeza que deseja excluir o colaborador ${coll.name}?`)){

      try{
        await toast.promise(apiAgendaNaMao.delete(`/deleteCollaborator/${coll.id}`),{
          pending: 'Excluindo Colaborador',
          success: `Colaborador Excluído com sucesso!`,
        }) 
        window.location.reload()
        
      }catch(err){
        const response = (err.response.data)
        toast.error(response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } 
  }

  const responsiveOptions = [

    {
      breakpoint: '1400px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1, 
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '575px',
      numVisible: 2,
      numScroll: 1,
      width: '350px'
    }
  ];

  const productTemplate = (filterCollaborator) => {
    return (
      <div id="divCarroselPrincipal">

        <div id="divPhoto">
          <img  src={filterCollaborator.path}/>
        </div>

        <div id="divItensCarrosel">
          <h4><PersonIcon style={{width: '19px',color: 'black'}}/> Nome: {filterCollaborator.name}</h4>
          <h6><LocalPhoneIcon style={{width: '19px',color: 'black'}}/> Telefone: {filterCollaborator.tel}</h6>
          <h6><MailOutlineIcon style={{width: '19px',color: 'black'}}/> Email: {filterCollaborator.email}</h6>

          <h6>Administrador: {Moderator(filterCollaborator.moderator)}</h6>
          <p>{filterCollaborator.carbs}</p>
          
          <div id="divButtonDeleteEdite">
            <ModalEditeColla >{filterCollaborator}</ModalEditeColla>
            <button id="buttonDelete" onClick={()=> deleteColl(filterCollaborator)}> <DeleteForeverIcon style={{width: '20px', marginTop: "5px"}} /></button>
          </div>
        </div>
      </div>
    );
  };


  return(
    <Container>

      <div className="Pagname">
        <h1 style={{color: 'white'}}>Colaboradores</h1>
      </div>
            
      <ContainerItens>
        <ModalCollaborator />
        <Carousel 
          value={filterColl} 
          numVisible={2} 
          numScroll={1} 
          responsiveOptions={responsiveOptions} 
          itemTemplate={productTemplate}
          orientation="vertical"
          verticalViewPortHeight='450px' 
        />
      </ContainerItens>
    </Container>
  )
}
import React, {useEffect, useState} from "react";
import {useContextColl} from "../../../hooks/CollaboratorContext"
import {useUser} from "../../../hooks/UserContext"
import {useService} from "../../../hooks/ServiceContext"
import * as Yup from 'yup'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import apiAgendaNaMao from "../../../services/api"
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { 
    Container,
    ContainerItens,
    ContainerListComissoes
} from "./styles"



export function Comisssao(){

    const schema = Yup.object().shape({
        ServicesId: Yup.string().required('Campo Obrigatório!'),
        CollaboratorsId: Yup.string().required('Campo Obrigatório!'),
        comissao: Yup.number('Campo Obrigatório!').required('Campo Obrigatório!'),
        descricao: Yup.string(),
        color: Yup.string()
    })

    const {collaborators} = useContextColl({})
    const {userData} = useUser({})
    const {services} = useService({})

    const [Colls, setColls] = useState([])
    const [Services, setServices] = useState([])
    const [Comissoes, setComissoes] = useState([])
    const [CollsFilter, setCollsFilter] = useState([])
    const [ServicesFilter, setServicesFilter] = useState([])
    const [ComissoesFilter, setComissoesFilter] = useState([])

    const [selectedComissao, setSelectedComissao] = useState(null);

    useEffect(()=>{
      async  function loadRecs(){
        const {data: loadComissoes} = await apiAgendaNaMao.get('comissao')
        setComissoes(loadComissoes)
      }
      loadRecs()
    },[])


    useEffect(()=>{
        setColls(collaborators)
        setServices(services)

        const filterColl = Colls.filter(i => i.UserId === userData.id || i.UserId === userData.UserId)
        setCollsFilter(filterColl)

        const filterServices = Services.filter(i => i.UserId === userData.id || i.UserId === userData.UserId).filter(i => i.active === "Sim")
        setServicesFilter(filterServices)

        const filterComissoes = Comissoes.filter(i => i.UserId === userData.id || i.UserId === userData.UserId)
        setComissoesFilter(filterComissoes)
    },[collaborators, userData.id, Colls, Services, services, Comissoes])

    const {
        register, 
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
    })
   

    const onSubmit = async (data) => {

        try{

            if(userData.collaborator){
                const response = await toast.promise(apiAgendaNaMao.post('comissao',{
                    UserId: userData.UserId,
                    ServicesId: data.ServicesId,
                    CollaboratorsId: data.CollaboratorsId,
                    comissao: data.comissao,
                    descricao: data.descricao
                }),{
                    pending: 'Criando Comissão',
                    success: `Comissão Criado com sucesso!`,
                })
                
                window.location.reload()
                return response
            }

            const response = await toast.promise(apiAgendaNaMao.post('comissao',{
                UserId: userData.id,
                ServicesId: data.ServicesId,
                CollaboratorsId: data.CollaboratorsId,
                comissao: data.comissao,
                descricao: data.descricao
            }),{
                pending: 'Criando Comissão',
                success: `Comissão Criado com sucesso!`,
            })
             window.location.reload()
            return response

        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
                theme: "light",
            });
        }
    }

    const formatComissoesTable = ComissoesFilter.map(i => ({
        id: i.id,
        Colaborador: i.Collaborator.name,
        Servico: i.service.name,
        Comissao: i.comissao,
        descricao: i.descricao
    }))

    async function deleteComissao(selectComissao){

        try{
            if( window.confirm(`Tem certeza que deseja excluir essa Comissão?`)){
                await apiAgendaNaMao.delete(`/comissao/${selectComissao}`)
                window.location.reload()
            }
        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }


    return(
        <Container>
            <h1 style={{color: 'white'}}>Comissão</h1>

            <ContainerItens>
                <div id="divTitleItens">
                    <h4>Adicionar Comissão</h4>
                </div>

                <div id="divDate">
                    <form  method="post" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <label>Colaborador:</label>
                        
                        <select {...register("CollaboratorsId", {required: true})}>
                            <option value={Array(1)} >Selecione um Colaborador</option>
                            {CollsFilter && CollsFilter.map(i => (
                                <option  key={i.id} value={i.id}>{i.name}</option>
                            ))}
                                
                        </select>
                        <p>{errors.CollaboratorsId? 'Campo obrigatório' : ''}</p>
                        
                        <label>Serviço:</label>
                        <select {...register("ServicesId", {required: true})} >
                            <option value={Array(1)}>Selecione um Serviço</option>
                            {ServicesFilter && ServicesFilter.map(i => (
                                <option key={i.id} value={i.id}>{i.name}</option>
                            ))}
                                
                        </select>
                        <p>{errors.ServicesId? 'Campo obrigatório' : ''}</p>
                        

                        <label> Comissão:</label>
                        <p>
                            <input {...register("comissao", {required: true})} type="number"/>
                            {errors.comissao? 'Campo obrigatório' : ''}
                        %</p>
                        

                        <label >Observação</label>
                        <textarea {...register("descricao")} />

                        <button type="submit">Salvar</button>
                        
                    </form>
                    
                </div>
            </ContainerItens>

            <ContainerListComissoes>
                <div id="divTitleItens">
                    <h4>Comissão Salvas</h4>
                </div>

                <DataTable onSelectionChange={(e) => deleteComissao(e.value.id)} selection={selectedComissao} selectionMode="single" stripedRows dataKey="id" value={formatComissoesTable} paginator rows={5} tableStyle={{ minWidth: '20rem', minHeight: '3rem', fontSize: '12px' , backgroundColor: '#FFF8DC',}}>
                    <Column field="Colaborador" header="Colaborador"></Column>
                    <Column field="Servico" header="Serviço"></Column>
                    <Column field="Comissao" header="Comissão%"></Column>
                    <Column field="descricao" header="Observação"></Column>
                    <Column onSelectionChange={(e) => deleteComissao(e.value.id)} body={<DeleteForeverIcon/>} ></Column>
                </DataTable>
            </ContainerListComissoes>
        </Container>
    )
}
import styled from "styled-components";


export const Container = styled.div`
 width: 100vw;

    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }

`

export const ContainerItens = styled.div`
    
    form{
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 290px;

        @media (min-width: 500px) {
            width: 80vw;
        }   

        @media (min-width: 650px) {
            width: 87vw;
        } 

        @media (min-width: 800px) {
            width: 100vw;
        } 
        
        h4{
            margin-right: 270px;
        }

       

        #dadosConta{
            position: relative;
            left: 70px;
            width: 200px;
        }

        #divPhoto{
         
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            margin-top: 40px;
            
            img{
                width: 100px;
                
            }

            input[type="file"]{
                width: 260px;
            }

            label{
                cursor: pointer;
            }
        }

        input{
            background-color: transparent;
            border: 1px solid black;
            border-radius: 4px;
            margin-top: 2px;
            padding-left: 3px;
            width: 160px;
        }
        

        

        select{
            background-color: transparent;
            border: 1px solid black;
            border-radius: 4px;
            margin-top: 2px;
            height: 20px;
        }

        #buttonSalve{
            font-weight: bolder;
            width: 150px;
            height: 23px;
            margin-top: 15px;
            cursor: pointer;
            background: linear-gradient(90deg, rgba(17,189,8,1) 0%, rgba(31,111,1,1) 50%, rgba(17,189,8,1) 100%);
            color: #fff;
            border: none;
            border-radius: 5px;
            box-shadow: 1px 1px 5px #000;
            margin-bottom: 30px;

            &:hover{
                opacity: 0.8;

            }

            &:active{
                opacity: 0.6;

            }
        }
    }

    p{
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: bold;
    }
    
    #list1{
       display: grid;
       grid-template-columns: 1fr 1fr;
       background-color: #DCDCDC;
       border-radius: 10px;
       width: 350px;
       padding-top: 7px;
       padding-left: 7px;
       margin-top: 10px;
       margin-bottom: 20px;
    }
    #listForm{
       display: grid;
       grid-template-columns: 1fr 1fr;
       background-color: #DCDCDC;
       border-radius: 10px;
       width: 350px;
       padding-top: 7px;
       padding-left: 7px;
       margin-top: 5px;
    }

    #divRedeSocial{
       display: grid;
       grid-template-columns: 1fr 1fr;
       background-color: #DCDCDC;
       border-radius: 10px;
       width: 350px;
       padding-top: 7px;
       padding-left: 7px;
       margin-top: 5px;
    }
`
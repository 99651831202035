 import React from "react";
import { Container,} from "./styles";
import {SlideMenuAdmin} from '../../components/SlideMenuAdmin'
import {Agendamentos} from "./Agendamentos";
import {Collaborator} from './Collaborator'
import {Servicos} from './Servicos'
import {Horarios} from "./Horarios"
import paths from "../../constants/paths";
import { Comandas } from "./Comandas";
import {Financeiro} from "./Financeiro"
import {ConfigConta} from "./ConfigConta"
import { Despesas } from "./Despesas";
import { AgendaOnline } from "./AgendaOnline";
import {BackupPass} from "../BackupPass"
import { Dashboard } from "./Dashboard";
import {Suporte} from "./Suporte"
import {Comisssao} from "./Comissao"
import { useLocation } from "react-router-dom";


export function Admin(){

    const location = useLocation()

    return(
        <Container>

           <SlideMenuAdmin path={location.pathname}/>
           {location.pathname === paths.Agenda && <Agendamentos />}
           {location.pathname === paths.Collaborator && <Collaborator />}
           {location.pathname === paths.Servicos && <Servicos />}
           {location.pathname === paths.Horario && <Horarios />}
           {location.pathname === paths.Comandas && <Comandas />}
           {location.pathname === paths.financeiro && <Financeiro />}
           {location.pathname === paths.config && <ConfigConta />}
           {location.pathname === paths.Despesas && <Despesas />}
           {location.pathname === paths.AgendaOnline && <AgendaOnline />}
           {location.pathname === paths.RecoverPass && <BackupPass />}
           {location.pathname === paths.Dashboard && <Dashboard />}
           {location.pathname === paths.Suporte && <Suporte />}
           {location.pathname === paths.Comissoes && <Comisssao />}
        </Container>
        
    ) 

}

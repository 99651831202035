import React, {useEffect, useState} from "react";
import apiAgendaNaMao from "../../../services/api";
import { Container, ContainerItens} from "./styles";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ModalService } from "../../../components/ModalService";
import { ModalEditeService } from "../../../components/ModalEditeService";
import {useUser} from '../../../hooks/UserContext'
import {useService} from '../../../hooks/ServiceContext'
import { toast } from 'react-toastify';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export function Servicos(){

  //Estado para gravar as infor dos services
  const [Services, setService] = useState([])
  const [filterService, setFilterService] = useState([])
  const {userData} = useUser()
  const {putServiceData} = useService() 
  const {id} = useParams([])

  //useEffect para quando minha pág iniciar chamar a api e trazer os serviços
  useEffect(() => {
    
    async function loadService(){

      const {data: services} = await apiAgendaNaMao.get('service')
        
      setService(services)
      putServiceData(services)

    }
    loadService()
  
  }, [])


  useEffect(() => {

    const filterService = Services.filter(service => service.UserId === userData.id || service.UserId === userData.UserId)
    setFilterService(filterService);
  
  }, [Services, userData.id, userData.UserId, id])

  //função para quando clicar do button delete, chama api passa o id e deleta.
  async function deleteService(services){
    
    const id = services.id
    
    try{
      
      if(window.confirm(`Tem certeza que deseja excluir o Serviço ${services.name}?`)){
        await toast.promise(apiAgendaNaMao.delete(`/service/${id}`),{
          pending: 'Excluindo Serviço',
          success: `Serviço Excluído com sucesso!`,
        }) 
        window.location.reload()
      } 
    }catch(err){
      
      const response = (err.response.data)
      toast.error(response, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
   
  }

  //formatando o valor para a moeda real
  let formatReal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
 
  return(
    <Container>
      <div className="Pagname">
        <h1 style={{color: 'white'}}>Serviços</h1>
      </div>
      <ModalService />
      <ContainerItens>
        

          {filterService && filterService.map(filterService => (
            <div key={filterService.id} id="divCarroselPrincipal">
              
              <div id="divItens">
                <h4><MiscellaneousServicesIcon style={{width: '19px',color: 'black'}}/> Serviço: {filterService.name}</h4>
                <h6><AccessTimeIcon style={{width: '15px',color: 'black'}}/> Tempo: {moment(filterService.time).format('HH')}h {moment(filterService.time).format('mm')}m</h6> 
                <h6><AttachMoneyIcon style={{width: '15px',color: 'black'}}/> Preço: {formatReal.format(filterService.price)}</h6>
                <h6>Serviço Ativo? {filterService.active}</h6>
                <div id="divButtonDeleteEdite">
                  <ModalEditeService >{filterService}</ModalEditeService>
                  <button id="buttonDelete" onClick={() => deleteService(filterService)} > <DeleteForeverIcon style={{width: '20px', marginTop: "6px", color: 'black'}} /></button>
                </div>
              </div>
            </div>
          ))}
      </ContainerItens>
    </Container>
  )
}
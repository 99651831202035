import React, {useState, useEffect} from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from "moment-timezone";
import {useAgenda} from '../../../hooks/AgendamentoContext';
import { Container, ContainerCalendar,} from "./styles";
import apiAgendaNaMao from "../../../services/api";
import util from "../../../util";
import 'moment/locale/pt-br';
import {useContextColl} from '../../../hooks/CollaboratorContext';
import { BlockAgenda } from "../../../components/BlockAgenda";
import {useUser} from '../../../hooks/UserContext';


const localizer = momentLocalizer(moment)

export function Agendamentos(){

    //Recuperando dados dos hooks de colaborador
    //const collaborators = useContextColl([])

    //formatando dados dos hooks de colaborador
   // const formatCollaborator = collaborators.collaborators

    // mandando dados pela função put para armanezar dados dentro do estado de agendamentos
    const {userData} = useUser({})
    const {putAgendaData} = useAgenda()
    const {putCollData} = useContextColl({})
    
    // variavel que recebe os dados pelo setAgen... e guarda em agendamentos
    const [agendamentos, setAgendamentos] = useState([])

    const [Blocks, setBlocks] = useState([])
    const [BlocksFilter, setBlocksFilter] = useState([])
    const [Colls, setColls] = useState([])
    const [CollsFilter, setCollsFilter] = useState([])
    const [CollsSelect, setCollsSelect] = useState([])

    
    
    //Função é chamada quando o select do colaborador altera, ai chama api 
    async function filterColl(coll){
       
        try{
            //Variavel para pegar só o valor do id do coll que vem do select
             const idColl = coll.target.value

            setCollsSelect(idColl)

            //Chamada da api, pegando só os valores que estão em data
            const {data} = await apiAgendaNaMao.post('agendaFilter',{
                CollaboratorsId: idColl
            })
            putAgendaData(data) 
            setAgendamentos(data) // Pegando e salvando dados no agendamento
            return data 
        }catch(err){
            
        }
    }

    useEffect(()=>{
       async function loadRecs(){
            const {data: agendaBloqueio} = await apiAgendaNaMao.get('agendaBloqueio')
            setBlocks(agendaBloqueio)

            const {data: colaboradores} = await apiAgendaNaMao.get('collaborator')

            setColls(colaboradores)
            putCollData(colaboradores)
        }

       loadRecs()
       
    },[])

    useEffect(()=>{
        
       const filterBlockUser = Blocks.filter(blocks => blocks.UserId === userData.id  || blocks.UserId === userData.UserId).filter(blocksColl => blocksColl.CollaboratorsId === CollsSelect)
       setBlocksFilter(filterBlockUser)
       
       const filterColls = Colls.filter(Coll => Coll.UserId === userData.id || Coll.UserId === userData.UserId)
       setCollsFilter(filterColls)
       
    },[Blocks, userData.id, Colls, userData.UserId, CollsSelect])

    const formatEventos = agendamentos.map((agendamento) =>(
        {
            title: ` Serviço - ${agendamento.service.name} | Cliente - ${agendamento.name} | Status = ${agendamento.status} `,
            start: moment(agendamento.date).tz("America/Sao_Paulo").toDate(),
            end: moment(agendamento.date).tz("America/Sao_Paulo").add(util.hourToMinutes(moment(agendamento.service.time).tz("America/Sao_Paulo").format("HH:mm")), 'minutes').toDate(),
            colorEvento: `${agendamento.color}`
        }
    ))  
    
    const blocks = BlocksFilter.map((blocks)=> ({
        title: `${blocks.title}`,
        start: moment(blocks.data).tz("America/Sao_Paulo").toDate(),
        end: moment(blocks.data).tz("America/Sao_Paulo").add(util.minutesBlock(blocks.start, blocks.end),'minutes')
    }))

    

    const formatPeriodo = (periodo) =>{

        let finalRange = {}

        if(Array.isArray(periodo)){
            finalRange = {
                start: moment(periodo[0]).tz("America/Sao_Paulo").format('YYYY/MM/DD'),
                end: moment(periodo[periodo.length -1]).tz("America/Sao_Paulo").format('YYYY/MM/DD'),
            }
        }else{
            finalRange = {
            start: moment(periodo.start).tz("America/Sao_Paulo").format('YYYY/MM/DD'),
            end: moment(periodo.end).tz("America/Sao_Paulo").format('YYYY/MM/DD'),
            }
        }
        
        return finalRange
    }

    const messages = {
        next: '>',
        previous: '<',
        week: 'Semana',
        today: 'Hoje',
        month: 'Mês',
        day: 'Dia',
        agenda: 'Agenda',
        date: 'Data',
        time: 'Hora',       
    }
    
    
    return(
        <Container>
            
            <h1 style={{color: 'white'}}>Agendamentos</h1>
            <ContainerCalendar>  
                <select onChange={filterColl}>
                    <option value='102030405060abcdefga'>Selecione um Colaborador</option>
                    {CollsFilter && CollsFilter.map((collaborator)=>( 
                        <option key={collaborator.id} value={collaborator.id}> Colaborador(a): {collaborator.name}</option>
                    ))}
                </select>
                    
                <Calendar className="calendar"
                    localizer={localizer}
                    onRangeChange={(periodo) => {
                        formatPeriodo(periodo)
                    }}
                    events={formatEventos}
                    backgroundEvents={blocks}
                    defaultView='day'
                    selectable
                    popup
                    messages={messages}
                    eventPropGetter={(formatEventos) => {
                        const backgroundColor = formatEventos.colorEvento ? formatEventos.colorEvento : 'blue';
                        return { style: {backgroundColor, fontSize: '12px', color: 'white'}} 
                    }}
                    timeslots={1}
                    min={new Date(2017, 10, 0, 6, 0, 0)}
                    max={new Date(2017, 10, 0, 22, 0, 0)} 
                />
            </ContainerCalendar> 
            <div id="modalBlockAgenda">
                <BlockAgenda />
                
            </div>
            
        </Container>
    )


}
import styled from "styled-components";


export const Container = styled.div`
    width: 100vw;
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    #DivMyAgendamentos{
        position: absolute;
        top: 5px;
        right: 0px;
        display: flex;
        align-items: center;

        p{
            font-size: 12px;
            font-weight: bold;
        }
    }

    #imgWhats{
        width: 40px;   
    }

    #linkImgWhats{
        cursor: pointer;
        position: fixed;
        bottom: 30px;
        right: 20px;
        
    }

    h3{
      margin-left: 10px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    #BackForm{
        border: none;
        background-color: transparent;
        margin-left: 15px;
        cursor: pointer;

        &:hover{
            background-color: orange;
        }
    }

    #divItensVoltar{
        display: flex;
        align-items: center;
        margin-top: 20px;
    }

    #divSection{
        height: 80vh;
        display: flex;
        align-items: center;
        flex-direction: column;

        #buttonOutraData{
            display: flex;
            align-items: center;
            justify-content: center;
            color: black;
            width: 190px;
            height: 25px;
            font-size: 15px;
            background-color: transparent;
            border: none;
            font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            margin-top: 25px;
            cursor: pointer;
        }
    }
 

`

export const ContainerItens = styled.div`
    
    display: flex;
    width: 100vw;
    align-items: center;
    flex-direction: column;

    #divCaixaPesquisa{
        display: flex;
        margin-left: 10px;

        input{
            border: none;
            background-color: #DCDCDC;
            width: 235px;
            height: 40px;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            padding-left: 5px;
        }

        button{
            background-color: #A9A9A9;
            border: none;
            width: 40px;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            margin-left: 3px;
        }
    }
   
`

export const ButtonAgendamentos = styled.button`
    cursor: pointer;
    margin-right: 10px;
    border: none;
    display: flex;
    align-items: center;
    background: transparent;
    &:hover{
        opacity: 0.8;

    }

    &:active{
        opacity: 0.6;

    }

`

export const SectionPhoto = styled.div`
   margin-top: 5px;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 20px;
  
    h2{
        font-family:  sans-serif;
        font-size: 20px;
        font-weight: bold;
    }

    p{
        font-family:  sans-serif;
        font-size: 12px;
        margin-top: 12px;
        font-weight: bold;
    }

    #divRedeSocial{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
`
export const SectionService = styled.section`
    //background: #DCDCDC;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 10px;
    height: 100vh;
    align-items: center;

    

    .divServices{
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        box-shadow: 0 4px 4px -2px #989898;
        -webkit-box-shadow: 0 5px 8px -4px #FFDEAD;
        display: grid;
        grid-template-columns: 1px 290px 25px;
        margin-bottom: 10px;
        height: 80px;
      
         
        #divServiceInfor{
            display: flex;
            align-items: baseline;
            justify-content: center;
            flex-direction: column;

            #nameSercice{
                margin-left: 3px;
                margin-top: 3px;
                margin-bottom: 2px;
                font-size: 14px;
                color: black;
                font-family: sans-serif;
                font-weight: bold;
            }

            #price{
                font-size: 12px;
                color: #808080;
                font-family: sans-serif;
                display: flex;
                align-items: center;
            }

            #timeService{
                font-size: 12px;
                color: #808080;
                font-family: sans-serif;
                display: flex;
                align-items: center;
            }
        }

       #divIconsSeta{
            display: flex;
            align-items: center;
        }
    }

    
`


export const SectionDay = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
   // overflow: scroll;
    margin-left: 5px;
    gap: 10px;
    

    @media(max-width: 420px){
        grid-template-columns: 1fr 1fr 1fr ;
    }

    @media(max-width: 330px){
        grid-template-columns: 1fr 1fr;
    }

    @media(min-width: 600px){
        grid-template-columns: 1fr 1fr 1fr 1fr ;
    }

    @media(min-width: 900px){
        grid-template-columns: 1fr 1fr 1fr 1fr ;
    }
    
    #slotDiasDisp{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #D3D3D3;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
        -moz-box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
        box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
        width: 70px;
        height: 70px;
        background: #FFFFFF;
        margin-top: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 10px;
        font-weight: bold;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        color: black;
        &:hover{
            background-color: orange;
        }

        &:active{
            background-color: orange;
        }
    }

`


export const SectionMes = styled.section`
   display: grid;
   grid-template-columns: 200fr;
   height: 350px;
   overflow: scroll;

    #slotMesDisp{

        width: 180px;
        border: 1px solid #D3D3D3;
        background-color: #B0C4DE;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
        -moz-box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
        box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
        border: none;
        text-align: left;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        cursor: pointer;
        &:active{
            background-color: orange;
        }
   }

`

export const SectionColl = styled.section`
    display: flex;
    flex-direction: row;
    overflow: scroll;
    align-items: center;

    .divColl{
        display: flex;
        align-items: center;
    }

    #buttonColl{
        border: none;
        margin-left: 10px;
        background-color: transparent;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        align-items: center;
        flex-direction: column;
        
        &:active{
            background-color: orange;
        }
    }

`

export const SectionHours = styled.section`
    display: grid;
   // grid-template-columns: 1fr 1fr ;

    @media(min-width: 180px){
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media(min-width: 300px){
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media(min-width: 600px){
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media(min-width: 800px){
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media(min-width: 1000px){
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }


    #divHours{
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        margin-left: 10px;

        @media(min-width: 414px){
            margin-left: 30px;
            gap: 20px;
            margin-right: 20px;
        }

        #buttonSlotoHours{
            width: 75px;
            height: 30px;
            border: none;
            border-radius: 10px;
            color: black;
            font-size: 14px;
            background-color: #6666;
            
            &:active{
                background-color: orange;
            }
        }
    }

    #divSemHorarios{
        margin-left: 50px;
        width: 70vw;
        
    }

`

export const SectionComanda = styled.section`

    display: flex;
    flex-direction: column;
    align-items: center;
    
    button{
        border: none;
        cursor: pointer;

        &:hover{
            color: red;
        }
    }

    #divComanda{
        display: grid;
        grid-template-columns: 1fr 220px 30px;
        border-radius: 5px;
        height: auto;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
        -moz-box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
        box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
        margin-bottom: 10px;
        
        #divMesDiaHora{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 70px;
            gap: 5px;
            background-color: #A9A9A9;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            h2{
                font-size: 18px;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            }

            h4{
                font-size: 16px;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            }

            h5{
                font-size: 15px;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            }
        }

        #divServiceNamePrice{
            display: flex;
            flex-direction: column;
            justify-content: left;
            padding-left: 10px;
            gap: 10px;


            p{
                font-size: 14px;
                font-family: Arial, Helvetica, sans-serif;
            }
        }
    }

    #divMaisService{
        display: flex;
        align-items: center;
        button{
            display: flex;
            align-items: center;
            font-size: 12px;
            background-color: white;
            font-weight: bold;
            &:hover{
            color: green;
            }
        }
    }
        
   
`

export const SectionInforCliente = styled.section`

    margin-top: 50px;
    

    #formulario{
        
        gap: 5px;
        display: flex;
        flex-direction: column;

        span{
            font-size: 10px;
            color: red;
        }

        #ConfirmeAgendamento{
            background-color: green;
            border: 1px solid black;
            height: 24px;
            width: 180px;
            font-size: 12px;
            border-radius: 4px;
            color: white;
            margin-top: 10px;
            margin-bottom: 30px;
            &:hover{
                opacity: 0.7;
            }
        }

        label{
            font-size: 13px;
        }

        input{
            border-radius: 4px;
            border: 1px solid #6666;
            background-color: wheat;
            height: 24px;
        }

    }

   
`

export const SectionMyAgendamentos = styled.section`

    width: 90vw;
    height:  90%;
    overflow: scroll;
  

    #divFilterAgendamentos{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 5px;
    }

    input{
        width: 190px;
        height: 22px;
        outline: 1;
        border-radius: 5px;
        border: 1px solid black;
        cursor: pointer;
        padding-left: 4px;
    }

    #divButtons{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 10px;

        button{
            border: none;
            width: 70px;
            height: 20px;
            margin-top: 6px;
            border-radius: 3px;
            background: #6666;
            font-size: 12px;
            color: black;
            font-weight: bold;
            cursor: pointer;
            font-family: Arial, Helvetica, sans-serif;

            &:hover{
               background-color: orange;
            }

            &:active{
                background-color: orange;
            }
        }
    }

    #listAgendamentos{
        background-color: #6666;
        padding: 10px;
        margin-top: 20px;
        border-radius: 20px;
        font-size: 14px;
        
        button{
            background-color: transparent;
            border: none;
            border-radius: 5px;
            cursor: pointer;

            :hover{
                background-color: red;
            }
        }
    }

`

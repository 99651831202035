import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    
    p{
        color: white;
        font-size: 13px;
    }

    a{
        cursor: pointer;
        color: red;
        font-weight: bold;
        font-size: 12px;
    }
`

import React from "react";
import {Container} from './styles'
import {useUser} from '../../hooks/UserContext'
import { useNavigate } from "react-router-dom";
import {Notifications} from "../Notification"

export function Header(){

    const {logout} = useUser()
    const {userData} = useUser()
    const navigate = useNavigate()

    const logoutUser = () => {
        logout()
        navigate('/')
    }

    return(
        
        <Container>
            <Notifications/>
            <p>Olá, {userData.name}</p>
            <a onClick={logoutUser}>  Sair </a>       
        </Container>
        
    )


}

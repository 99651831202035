import styled from "styled-components";

export const Container = styled.div`
   
  #buttonBlockAgenda{
    border: none;
    background-color: transparent;
    cursor: pointer;

    :hover{
      opacity: 0.8;
    }

    &:active{
      opacity: 0.6;
    }
  }
`

export const ContainerItens = styled.div`
   
  background: #D3D3D3;
  position: absolute;
  top: 30%;
  left: 10%;
  width: 80vw;
  border: 1px solid #4B0082;

  #formItens{
    display: flex;
    color: #4B0082;
    font-weight: bold;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-top: 10px;

    select{
      width: 160px;
      height: 22px;
      border: 1px solid black;
      padding-left: 5px;
      border-radius: 5px;
    }

    input{
      width: 160px;
      height: 22px;
      border: 1px solid black;
      padding-left: 5px;
      border-radius: 5px;
    }

    textarea{
      margin-top: 1px;
      border: 1px solid black;
      padding-left: 5px;
      border-radius: 5px;
    }

    option{
      font-size: 11px;
      border-radius: 5px;
    }

    p{
      margin-bottom: 2px;
      margin-top: 10px;
    }

    button{
      margin-top: 10px;
      background-color: #008000;
      color: #FFFFFF;
      border: none;
      height: 22px;
      font-weight: bold;
      border-radius: 5px;
    }

    #ListBloqueio{
      border: none;
      height: 22px;
      background-color: blue;
      cursor: pointer;
    }
  }

`

export const ContainerNavModal = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 80vw;
  background: #4B0082;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;

  #nameHead{
    color: #ffffff;
    margin-left: 10px;
    font-size: 12px;
    font-weight: normal;
  }
  
  #buttonExit{
    font-size: 26px;
    cursor: pointer;
    color: #ffffff;
    border: none;
    font-weight: bold;
    background: transparent;
    margin-right: 10px;
    border: none;
    
    &:hover{
      opacity: 0.8;
      background: red;
      color: #fff;
    }

    &:active{
      opacity: 0.6;  
    }
  }

`

export const ListBloqueios = styled.div`

  display: flex;
  color: #4B0082;
  font-weight: bold;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-top: 10px;

  #AddBloqueio{
    border: none;
    height: 22px;
    background-color: blue;
    color: white;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
  }

`
import axios from "axios";

const apiAgendaNaMao = axios.create({
   baseURL: 'https://agendanamao-production.up.railway.app/'
   //baseURL: 'http://localhost:3001'
   
})

 apiAgendaNaMao.interceptors.request.use( async config => {
    const userData = await localStorage.getItem('agendanamao:userData')
    const token =  userData && JSON.parse(userData).token
    config.headers.authorization = `Bearer ${token}`
    return config
})  

export default apiAgendaNaMao
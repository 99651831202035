import styled from "styled-components";

export const Container = styled.div`

    width: 90vw;
    
    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }

`

export const ContainerItens = styled.div`

    margin-top: 50px;
    background-color: #FFF8DC;
    width: 90%;

    form{
        display: flex;
        align-items: baseline;
        flex-direction: column;
        margin-top: 10px;

        input{
            margin-bottom: 5px;
            width: 150px;
        }

        select{
            margin-bottom: 5px;
            width: 150px;
        }
        
        label{
            font-size: 12px;
        }

        a{
            font-size: 11px;
            margin-bottom: 5px;
            color: blue;
            cursor: pointer;
        }

        button{
            cursor: pointer;
            width: 150px;
            background-color: green;
            border: none;
            color: white;
            border-radius: 5px;

            &:hover{
                opacity: 0.9;
            }

            &:active{
                opacity: 0.8;
            }
        }

        p{
            font-size: 10px;
        }
      
    }

  
`

export const InputCategory = styled.input`

    margin-right: 2px;
   
  
`

export const ContainerDespesas = styled.div`

  margin-top: 20px;
  
  h3{
    margin-bottom: 10px;
  }

  button{
    border: none;
    display: flex;
    background-color: transparent;

    &:hover{
        background-color: red;
    }
  }

`
import React, {useEffect, useState} from "react";
import apiAgendaNaMao from "../../../services/api";
import { useUser } from "../../../hooks/UserContext";
import moment from "moment";
import listStatus from "./listStatus"
import imgWhats  from '../../../assets/whatsapp.png'
import { toast } from 'react-toastify';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ModalAddComandas } from "../../../components/ModalAddComandas";
import { ModalEditeComandas } from "../../../components/ModalEditeComandas";
import {
    Container,
    ContainerItens,
    ListLink,
    ContainerComands
} from '../Comandas/styles'

export function Comandas(){

    const {userData}=useUser({})
    const [Comandas, setComandas] = useState([])
    const [ComandasFiltradas, setComandasFiltradas] = useState([])
    const [ComandasFiltradasStatus, setComandasFiltradasStatus] = useState([])
    const [ActiveStatus, setActiveStatus] = useState(1)
    const [SelectDateFilter, setSelectDateFilter] = useState([])
    const [ComandasFiltradasDataSelect, setComandasFiltradasDataSelect] = useState([])
    const [StatusSelect, setStatusSelect] = useState([])

    useEffect(()=> {
        
        async function loadAgendamentos(){
            const {data} = await apiAgendaNaMao.get(`agendamento`)
            setComandas(data)
            setComandasFiltradasStatus(data)
        }

        loadAgendamentos()
    },[])

    useEffect(()=> {
        
        const filterComandas = Comandas.filter(comands => comands.UserId === userData.id || comands.UserId === userData.UserId)
        
        setComandasFiltradas(filterComandas)
        
    },[Comandas, userData.id, ComandasFiltradasStatus, userData.UserId, ])


    useEffect(()=>{
        const filterComandasDataSelect = ComandasFiltradas.filter(filterDate => moment(filterDate.date).format('YYYY-MM-DD') === moment(SelectDateFilter).format('YYYY-MM-DD')).filter(
            i => i.status === StatusSelect
        )

        setComandasFiltradasDataSelect(filterComandasDataSelect)
    },[ComandasFiltradas, SelectDateFilter])
    
    //ordenando comandas pela data de agendamento
    ComandasFiltradasDataSelect.sort(function(a,b){
        return moment(a.date) - moment(b.date)
    })

    //Criar efeito colateral para mudar status simultaneamente
    useEffect(()=> {

        const statusIndex = listStatus.findIndex( sts => sts.id === ActiveStatus)
        const newFilterComandas = Comandas.filter(comandas => comandas.status === listStatus[statusIndex].value)
        
        setComandasFiltradasStatus(newFilterComandas)
    },[Comandas,SelectDateFilter,listStatus])

  
    function statusComands(id,value){
        
        const newStatus = value 
        const Id = id

        try{

            async function setNewStatus(){
                try{
                    await toast.promise(apiAgendaNaMao.put(`updateAgendamento/${Id}`,{status: newStatus}),{
                        pending: 'Atualizando status',
                        success: `Status Atualizado com sucesso!`,
                    })

                    const newComandas = Comandas.map(comandas => {
                        return comandas.id === Id ? {...comandas, status: newStatus} : comandas
                    })
                    setComandas(newComandas) 

                }catch(err){
                    const response = (err.response.data)
                    toast.error(response, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
               
            }

            setNewStatus()

        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    function handleStatus(status){
        setStatusSelect(status.value)
        
        if(status.id === 1){
            setComandasFiltradasStatus(ComandasFiltradasDataSelect)
        }else{
            const newStatus = ComandasFiltradasDataSelect.filter( itens => itens.status === status.value)
            setComandasFiltradasStatus(newStatus)
        }
        setActiveStatus(status.id)
    }


    function selectFilterDate(date){
        const dataSelected = moment(date.target.value).format('YYYY-MM-DD')
        setSelectDateFilter(dataSelected)
    }

     async function deleteComanda(id){
        
        if(window.confirm('Tem certeza que deseja excluir essa comanda?')){
            try{
                await toast.promise( apiAgendaNaMao.delete(`/agendamento/${id}`),{
                    pending: 'Excluindo comanda',
                    success: `Comanda excluída com sucesso!`,
                })
                window.location.reload()
            }catch(err){
                const response = (err.response.data)
                toast.error(response, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    //formatar valor em real
    let formatReal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

    return(
        <Container>
            <h1 style={{color: 'white'}}>Comandas</h1>
            <ContainerItens>

                <div id="divFilterComanda">
                    <p>Filtrar Comandas:
                        <input defaultValue={moment().format('YYYY-MM-DD')} onChange={selectFilterDate} type="date"/>
                    </p>
                    <ModalAddComandas />
                </div>

                <hr></hr>  

                    <div id="divMenu">
                        {listStatus && listStatus.map(status => (
                            <ListLink isactivestatus={ActiveStatus === status.id ? 'sim' : 'não'} 
                            onClick={()=> handleStatus(status)} 
                            key={status.id}>{status.icon}{status.label}</ListLink>
                        ))}
                    </div>

                <hr></hr>


                <ContainerComands>
                    
                    {ComandasFiltradasDataSelect && ComandasFiltradasDataSelect.map((comands)=> (
                        <div id="listComands" key={comands.id}>
                            <div id="divStatus">
                                <p>Status:</p>
                                
                                <select key={comands.id} onChange={newStatus => { 
                                    statusComands(comands.id, newStatus.target.value)
                                    }} defaultValue={comands.status}>
                                        <option value='Concluído'>Concluído</option>
                                        <option value='Cancelado'>Cancelado</option>
                                        <option value='Pendente' >Pendente</option>
                                </select>
                                
                            </div>


                            <p>Cliente: {comands.name}</p>
                            <a target="_blank" rel="noreferrer" href={`https://wa.me//55${comands.tel}?text=Olá, ${comands.name}%20 você confirma seu agendamento para o dia ${moment(comands.date).format('DD-MM-YYYY')} às ${moment(comands.date).format('HH:mm')}h, com o colaborador ${comands.Collaborator.name} e serviço ${comands.service.name}?`} style={{display: 'flex'}}>
                                <p>Tel: {comands.tel}</p>
                                <img  alt="WhatsApp" style={{width: '15px', display: 'flex',  marginLeft: '2px'}} src={imgWhats}/>
                            </a> 
                            <p>Colaborador: {comands.Collaborator.name}</p>
                            <p>Serviço: {comands.service.name}</p>
                            <p>Data: {moment(comands.date).format('DD-MM-YYYY')}</p>
                            <p>Hora: {moment(comands.date).format('HH:mm')}</p>
                            <p>Forma Pagamento: {comands.tipo_pagamento? comands.tipo_pagamento : 'Não definido'}</p>
                            <p>Total: {formatReal.format(comands.valor_total? comands.valor_total : comands.service.price)}</p>
                            <p>Observação:</p>
                            <textarea disabled defaultValue={comands.observacao? comands.observacao : '...' }></textarea>
                            <div id="divDelete">
                                <ModalEditeComandas props={comands}/>
                                <button onClick={()=> deleteComanda(comands.id)}><DeleteForeverIcon style={{color: 'red'}}/></button>
                            </div>
                        </div>
                        
                    ))}

                </ContainerComands>

            </ContainerItens>

        </Container>
    )
}


import React, {useEffect, useState} from "react";
import * as Yup from "yup"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Listdays from "../ModalAddHours/days";
import apiAgendaNaMao from "../../services/api";
import PropTypes from 'prop-types'
import {useUser} from '../../hooks/UserContext'
import { toast } from 'react-toastify';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Select, Space } from 'antd';
import {
    Container,
    ContainerItens,
    ContainerOpenModal,
    ContainerModal,
    SelectDate,
    Error,
    InputTime
} from "./styles"


function ModalEditeHours({isOpen, setModalOpen, data}){

    const schema = Yup.object().shape({

        days: Yup.array().required().of(
            Yup.number()
        ),
        collaborator: Yup.string().required(),
       /*  services:Yup.array().of(
            Yup.number()
        ), */
        start: Yup.string(),
        end: Yup.string(), 
     
    })


    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });
   
       
    const [ListColl, setListColl] = useState([])
    const [Services, setService] = useState([])
    const [filterServices, setFilterServices] = useState([])
    const [FilterColl, setFilterColl] = useState([]) 
    const [open, setopenDiv] = useState(false)
    const {userData} = useUser({})
    const [value, setValue] = useState([]);
    //const [ExitModalColl, setExitModalColl] = useState(false)  
    
    const selectProps = {
        value,
        onChange: setValue,
      };
    const onSubmit = async dataInputs => {
       
        try{
        
            const formatDate = {

                collaborator: [
                    { id: dataInputs.collaborator}
                ],
                services: [
                    { 
                      id: value
                    },
                ],
                days: dataInputs.days,
                start: new Date(`2024-02-02T${dataInputs.start}`),
                end: new Date(`2024-02-02T${dataInputs.end}`),
                // id: data[0].id,
            }

            await toast.promise(apiAgendaNaMao.put(`/horarios/${data.id}`,formatDate),{
                pending: 'Editando Horário',
                success: `Horário Editado com sucesso!`,
            }) 
            window.location.reload()

    
        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        
    }

    

       useEffect(()=>{
    
            async function loadCollaborator(){
           
                const {data} = await apiAgendaNaMao.get('collaborator')
                setListColl(data)
           
            }
      
            async function loadService(){
            
                const {data} = await apiAgendaNaMao.get('service')
                setService(data)
            
            }

            loadService()
            loadCollaborator()
      
       },[]) 
      
        useEffect(()=>{
          
            const filterService = Services.filter(service => service.UserId === userData.id || service.UserId === userData.UserId)
            setFilterServices(filterService)
        
        
            const filterCollaborator = ListColl.filter(coll => coll.UserId === userData.id || coll.UserId === userData.UserId)
            setFilterColl(filterCollaborator)
      
        },[Services, userData.id, ListColl, userData.UserId])

   
   

    function openDiv(){

        if(!open){
          setopenDiv(true)
        }else{
          setopenDiv(false)
        }
    }

    function ExitModalEditeHours(setModalOpen){
        setModalOpen(false)
    }

    const options = filterServices.map(i => ({
        label: i.name,
        value: i.id
      }));
      
      const sharedProps = {
        mode: 'multiple',
        style: {
          width: '90%',
          height: '30px',
          
        },
        options,
        placeholder: 'Selecione...',
        maxTagCount: 'responsive',
      };

    async function deleteHours(){
    
        try{
      
            if(window.confirm(`Tem certeza que deseja excluir o Horário?`)){
              await toast.promise(apiAgendaNaMao.delete(`/horarios/${data.id}`),{
                pending: 'Excluindo Horário',
                success: `Horário excluído com sucesso!`,
              }) 
              window.location.reload()
            } 
        }catch(err){
            
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }


  if(isOpen){
    return(
        <Container>
          
            <ContainerModal>

            <ContainerOpenModal>
                <label id="nameHead">Edite Horários</label>
                <button id="buttonExit" onClick={() => ExitModalEditeHours(setModalOpen)}>X</button>
            </ContainerOpenModal>

            <form noValidate onSubmit={handleSubmit(onSubmit)}  method="post">
                <ContainerItens isacc={open === false ? 'block': 'none'}>

                    <div>
                        {Listdays.map(item => (
                            <div id="listDays" key={item.id} >
                            <p style={{fontWeight: 'bold'}} >{item.label}</p>
                            <input defaultChecked={item.value === parseInt(data.days)} {...register("days")} value={item.value} type="checkbox"/>
                            </div>
                        ))}
                        <Error>{errors.days?.message}</Error>
                    </div>

                    <div id="divData">

                        <label>Colaborador</label>
                        <SelectDate  {...register("collaborator")} >
                            <option hidden>Selecione</option>
                            {FilterColl && FilterColl.map(item =>(
                                <option  key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </SelectDate>
            

                        <label>Serviços</label>
                        <Space direction="vertical"
                            style={{
                                width: '100%',
                                height: '20px'
                            }}
                        >
                         <Select {...sharedProps}  {...selectProps}/>
                        </Space>
            

                        <label>Inicio</label>
                        <InputTime  defaultValue={data.start}{...register("start")} type="time"/>
                        <Error>{errors.start?.message}</Error>
            

                        <label>Fim</label>
                        <InputTime  defaultValue={data.end} {...register("end")} type="time"/>
                        <Error>{errors.end?.message}</Error>

                    </div>  

                </ContainerItens>

                <div id="divsubmit">
                    <button id="buttonSave" type="submit" >Atualizar</button>
                </div>
                </form>
                <div id="divDeleteHours">
                    <button onClick={deleteHours} id="buttonDelete"><DeleteForeverIcon /></button>
                </div>
            </ContainerModal>
        </Container>
    )
    }

    return null
}

export default ModalEditeHours

ModalEditeHours.propTypes = { 
    editeDellHous: PropTypes.object,
    cools: PropTypes.object
}
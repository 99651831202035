import styled from "styled-components";

export const Container = styled.div`


display: flex;
justify-content: end;
padding-bottom: 2%;
padding-right: 2%;

button{
background: transparent;
color: #000000;
border: none;
font-weight: bolder;
cursor: pointer;

&:hover{
    opacity: 0.8;
}

&:active{
    opacity: 0.6;
}

}

`

export const ContainerItens = styled.div`

    position: fixed;
    top: 20vh;
    left: 15vw;
    background: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 290px;
    height: 350px;
    box-shadow: 2px 2px 10px #9999;
    border-radius: 1%;

    form{
        display: flex;
        flex-direction: column;
        gap: 1vh;
    }

    select{
        width: 85%;
        height: 25px;
    }

    #divsubmit{
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: 15px;
        margin-bottom: 15px;

        #buttonSave{
            width: 60px;
            font-size: 11px;
            height: 20px;
            margin-top: 15px;
            cursor: pointer;
            background-color: green;
            color: #fff;
            border: none;
            border-radius: 5px;
            box-shadow: 1px 1px 5px #000;
            padding-top: 1px;

            &:hover{
                opacity: 0.8;

            }

            &:active{
                opacity: 0.6;

            }
        }   
    }
`

export const InputName = styled.input`
width: 85%;
height: 28px;
outline: 1;
border-radius: 5px;
border: 1px solid #A9A9A9;
cursor: pointer;

`

export const InputEmail = styled.input`
width: 85%;
height: 28px;
outline: 1;
border-radius: 5px;
border: 1px solid #A9A9A9;
cursor: pointer;

`
export const InputTel = styled.input`
width: 85%;
height: 28px;
outline: 1;
border-radius: 5px;
border: 1px solid #A9A9A9;
cursor: pointer;

`

export const InputPassword = styled.input`

width: 85%;
height: 28px;
outline: 1;
border-radius: 5px;
border: 1px solid #A9A9A9;
cursor: pointer;



`

export const Div = styled.div`

`


export const Error = styled.p `

color: red;
font-size: 10px;

`

export const P = styled.p `
font-size: 15px;
font-style: normal;
font-weight: bold;
`
export const Label = styled.p `

font-size: 15px;

`

export const ContainerNavModal = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background: #F4A460;
  display: flex;
  justify-content: space-between;
  align-items: center;

  #nameHead{
    color: black;
    margin-left: 10px;
    font-size: 12px;
    font-weight: normal;
  }

  #buttonExit{
    font-size: 18px;
    margin-top: 2px;
    cursor: pointer;
    color: black;
    border: none;
    font-weight: bold;
    background: transparent;
    width: 20px;
    border: none;
    margin-right: 5px;

    &:hover{
      opacity: 0.8;
      background: red;
      color: #fff;
    }

    &:active{
      opacity: 0.6;  
    }
  }

`

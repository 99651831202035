import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from "moment-timezone";
import apiAgendaNaMao from "../../../services/api";
import 'moment/locale/pt-br';
import { ModalAddHours } from "../../../components/ModalAddHours";
import ModalEditeHours from "../../../components/ModalEditeHours";
import {useUser} from '../../../hooks/UserContext'
import {Container, ContainerCalendar} from "./styles"


const localizer = momentLocalizer(moment)

export function Horarios(){

    const [modalEditeHours, setmodalEditeHours] = useState(false)
    const [clickModal, setclickModal] = useState([])
    const {userData} = useUser({}) 

    //Armazena todos os horarios quando inicia a aplicação
    const [hous, setHours] = useState([])

    const [filterHous, setFilterHous] = useState([])
    const [Colls, setColls] = useState([])
    const [FilterColls, setFilterColls] = useState([])
    const [SelectColl, setSelectColl] = useState([])

    //Armazena todos os horários formatado quando tem alteração no hous
    const [rows, setRows] = useState([])


    // 0 = Domingo, 1 = Segunda 
    const diasSemanaData = [
        new Date(2024, 2, 3, 0,0,0,0),
        new Date(2024, 2, 4, 0,0,0,0),
        new Date(2024, 2, 5, 0,0,0,0),
        new Date(2024, 2, 6, 0,0,0,0),
        new Date(2024, 2, 7, 0,0,0,0),
        new Date(2024, 2, 8, 0,0,0,0),
        new Date(2024, 2, 9, 0,0,0,0),

    ]

    useEffect(()=>{

        async function loadHour(){
        
            const {data} = await apiAgendaNaMao.get('horarios')
            setHours(data)

        }

        async function loadColls(){
        
            const {data} = await apiAgendaNaMao.get('collaborator')
            setColls(data)   
        }
        
        loadHour()
        loadColls()
    },[])

  
    useEffect(()=>{

        const filterHours = rows.filter(item => item.userId[0].id === userData.id || item.userId[0].id === userData.UserId).filter(coll => coll.colaboradorId === SelectColl)
        setFilterHous(filterHours)
        
        const filterColls = Colls.filter(item => item.UserId === userData.id || item.UserId === userData.user_id)
        setFilterColls(filterColls)

    },[rows, userData.id, userData.UserId,Colls, SelectColl])

    function createData(hours){
        
        return{
            colaborador: hours.collaborator[0].name,
            colaboradorId: hours.collaborator[0].id,
            serviço: hours.services.map(item => item.name),
            userId: hours.userId,
            days: hours.days,
            start:hours.start,
            end:hours.end,
            id: hours._id,
            color: hours.color
        }
    }

    useEffect(()=>{
        const newRows = hous.map(horarios => createData(horarios))
        setRows(newRows)

    },[hous])

    function selectColl(coll){
       const selectColl = coll.target.value
       setSelectColl(selectColl)
    }


    const formatEvents = filterHous.map((horarios) => horarios.days.map((days) =>({
        title: ` 
            Serviço: 
            ${horarios.serviço }
        `,

        start: new Date(
            diasSemanaData[days].setHours(
                parseInt(moment(horarios.start).format('HH')),
                parseInt( moment(horarios.start).format('mm')),
            )
        ),

        end: new Date(
            diasSemanaData[days].setHours(
                parseInt(moment(horarios.end).format('HH')),
                parseInt( moment(horarios.end).format('mm')),
            )
        ),
        id: horarios.id,
        collaborator: horarios.colaborador,
        services: horarios.serviço,
        colorEvento: `${horarios.color}`
        
    }))).flat()


    function editeDellHous(data){
        setmodalEditeHours(true)
        let utcAtualUser = moment.tz.guess()

        const newDate = [{
            start: moment.tz(data.start, 'YYYY-MM-DD', utcAtualUser).format('HH:mm'),
            end: moment.tz(data.end, 'YYYY-MM-DD', utcAtualUser).format('HH:mm'),
            days: moment(data.start).format('d'),
            collaborator: data.collaborator,
            services: data.services,
            id: data.id

        }]

        setclickModal(newDate)

    }

    return(

        <Container>
            <h1 style={{color: 'white'}}>Horários</h1>

            <ContainerCalendar>
            
                <div id="divNav">
                    <select onChange={selectColl}>
                        <option value={0}> Filtrar Colaborador</option>
                        {FilterColls && FilterColls.map(i => (
                            <option  key={i.id} value={i.id}  >{i.name}</option>
                        ))}
                    </select>
                    <ModalAddHours />
                </div>
            
                <Calendar className="calendar"
                    localizer={localizer}
                    toolbar={false}
                    formats={{
                        dateFormat: 'dd',
                        dayFormat: (date, culturem, localizer) => localizer.format(date, 'dddd', culturem)
                    }}
                    events={formatEvents}
                    selectble
                    onSelectEvent={editeDellHous}
                    defaultDate={diasSemanaData[moment().day()]}
                    popup
                    defaultView="week"
                    eventPropGetter={(formatEvents) => {
                        const backgroundColor = formatEvents.colorEvento ? formatEvents.colorEvento : 'blue';
                        return { style: {backgroundColor, fontSize: `8px`, display: 'flex', gap: '3px',}} 
                    }}
                    min={new Date(2017, 10, 0, 5, 0, 0)}
                    max={new Date(2017, 10, 0, 23, 0, 0)} 
                />
            </ContainerCalendar>

            {clickModal && clickModal.map(item =>(
                <ModalEditeHours 
                    isOpen={modalEditeHours}
                    data={item}
                    key={item.id}
                    setModalOpen={()=> {
                        setmodalEditeHours(!modalEditeHours)
                    }}
                />
            ))}
        </Container>
    )

}
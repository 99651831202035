import styled from "styled-components";


export const Container = styled.div`
    
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1{
       
        font-size: 25px;
    }

    h2{
        margin-top: 10px;
        font-size: 15px;
    }

    header{
        width: 100vw;
        background-color: 	#D2691E;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
            width: 150px;
            margin: 10px 10px 1px 20px;
        }

        button{
            border: none;
            margin-right: 20px;
            height: 50px;
            width: 200px;
            background-color: white;
            cursor: pointer;
            border-radius: 5px;
            &:hover{
                background-color: #A9A9A9;
            }

            &:active{
                background-color: #006400;
                color: white;
            }
        }
    }
`

export const ContainerItens = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 100px;
    width: 500px;

    @media (max-width: 513px) {
        grid-template-columns: 1fr;
        width: 250px;
    }

    #divCaixaPlanos{
        display: flex;
        flex-direction: column;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        justify-content: space-around;

        h5{
            font-size: 11px;
            margin-top: 2px;
        }

        h3{
            font-size: 15px;
            margin-top: 5px;
            margin-bottom: 2px;
        }

        p{
            font-size: 11px;
            display: flex;
            align-items: center;
            margin-top: 5px;
        }
        #divName{
            background-color: #A9A9A9;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            margin-bottom: 5px;
            
        }
    }
`

import React from "react";
import {useState} from 'react'
import Modal from "react-modal";
import * as Yup from "yup"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import apiAgendaNaMao from "../../services/api";
import { toast } from 'react-toastify';
import {useUser} from '../../hooks/UserContext'

import {
    Container,
    ContainerItens,
    InputName,
    InputEmail,
    InputTel,
    InputPassword,
    Div,
    Error,
    P,
    ContainerNavModal,
} from "./styles"

Modal.setAppElement("#root");

export function ModalCollaborator(){

    const schema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório'),
        tel: Yup.string().required('Campo obrigatório').min(9),
        moderator: Yup.boolean().required('Campo obrigatório'),
        password: Yup.string().required('Campo obrigatório').min(6),
        email: Yup.string().required('Campo obrigatório').email('Digite um email válido'),
        file: Yup.mixed().test('required', 'Campo obrigatório', value => {
            return value && value.length > 0
        })/* .test('fileSize', 'Carregue arquivos de até 2mb', value => {
            return value && value[0].size <= 200000
        }) */ 
    })  

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });
  
    const {userData} = useUser({}) 
 

    const cores = [
        "#6A5ACD", 
        "#836FFF", 
        "#191970", 
        "#000080", 
        "#800000", 
        "#CD5C5C", 
        "#FF7F50",
        "#FF6347",
        "#FF8C00",
        "#DDA0DD",
        "#DA70D6",
        "#9370DB",
        "#8A2BE2",
        "#8B008B",
        "#006400",
        "#008000",
        "#6B8E23",
        "#808000",
        "#556B2F",
        "#20B2AA",
        "#008B8B",
        "#4682B4",
        "#B8860B",
        "#A52A2A",
        "#696969",
        "#778899",
        "#2F4F4F",
        "#A0522D",
    ]

    const colors =  cores[Math.ceil(Math.random() * (cores.length - 1))];

    const onSubmit = async data => {

        try{

            if(userData.collaborator){
                //Utilizando o FormData para mandar os dados para api da forma Multipart requerida lá, que não é só dados tem arquivos também
                const collaboratorDataFormData = new FormData()
                collaboratorDataFormData.append('name', data.name)
                collaboratorDataFormData.append('tel', data.tel)
                collaboratorDataFormData.append('moderator', data.moderator)
                collaboratorDataFormData.append('password', data.password)
                collaboratorDataFormData.append('email', data.email)
                collaboratorDataFormData.append('file', data.file[0])
                collaboratorDataFormData.append('UserId', userData.UserId)
                collaboratorDataFormData.append('color', colors)
           
                await toast.promise(apiAgendaNaMao.post('collaborator', collaboratorDataFormData),{
                    pending: 'Criando novo Colaborador',
                    success: 'Colaborador Criado com sucesso',
                })
    
                closeModal()
                window.location.reload()

            }else{

                const collaboratorDataFormData = new FormData()
                collaboratorDataFormData.append('name', data.name)
                collaboratorDataFormData.append('tel', data.tel)
                collaboratorDataFormData.append('moderator', data.moderator)
                collaboratorDataFormData.append('password', data.password)
                collaboratorDataFormData.append('email', data.email)
                collaboratorDataFormData.append('file', data.file[0])
                collaboratorDataFormData.append('UserId', userData.id)
                collaboratorDataFormData.append('color', colors)
                
                await toast.promise(apiAgendaNaMao.post('collaborator', collaboratorDataFormData),{
                    pending: 'Criando novo Colaborador',
                    success: 'Colaborador Criado com sucesso',
                })
    
                closeModal()
                window.location.reload()
            }
            

        }catch(err){
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const [modalCollaborator, setCollaborator] = useState(false)

    //função para quando for aciosado vai abrir a modal
    function openModal(){
        setCollaborator(true)

    }

    //função para quando for aciosado vai fechar a modal
    function closeModal(){
        setCollaborator(false)
    }

    function showHide(){

        const password = document.getElementById('pass');
        const icon = document.getElementById("icon");
        
         if(password.type === "password"){
            password.setAttribute('type', 'text');
            icon.classList.add('hide')
        }else{
            password.setAttribute('type', 'password');
            icon.classList.remove('hide')
        } 
    }

    return(
        
        <Container>
            <Div>
                <button onClick={openModal}>+ Colaborador</button>
            </Div>
            
            <Modal
                isOpen={modalCollaborator}
                onRequestClose={closeModal}
                contentLabel="exemplo modal"
                overlayClassName="modal-overlay"
                className="modal-content"
                >
                <ContainerItens>
            
                    <ContainerNavModal>
                        <label id="nameHead">Adicionar Colaborador</label>
                        <button id="buttonExit" onClick={closeModal}>X</button>
                    </ContainerNavModal>
                
                    <form method="post"noValidate onSubmit={handleSubmit(onSubmit)}  > 

                        <InputName placeholder="Nome" {...register("name")} />
                        <Error>{errors.name?.message}</Error>

                        <InputEmail type="email" placeholder="Email" {...register("email")} />
                        <Error>{errors.email?.message}</Error>
                        
                        <InputTel placeholder="Tel" {...register("tel")}/>
                        <Error>{errors.tel?.message}</Error>
                    
                        <InputPassword  autoComplete="of" placeholder="Senha" id="pass" type="password"  {...register("password",)} />
                        <div id="icon" onClick={showHide} ></div>
                        <Error>{errors.password?.message}</Error>
                        
                        <P>Selecione uma Foto</P>
                        <input {...register("file")}   type="file" accept="image/png, image/jpeg" />
                        <Error>{errors.file?.message}</Error>

                        <P> Administrador?</P>
                        <select noValidate {...register("moderator")}>
                            <option value={true}>Sim</option>
                            <option value={false}>Não</option>
                        </select>
                        <Error>{errors.moderator?.message}</Error>

                        <div id="divsubmit">
                            <button type="submit" id="buttonSave">Cadastrar</button>
                        </div>
                    </form>

                
            </ContainerItens>

                
            </Modal>
            
        </Container>
    )

}